import { Component, OnInit, Input} from '@angular/core';
import { SCTDialog, SCTDialogAction } from '../sct-dialog/sct-dialog.model';
import { TranslateService } from '@ngx-translate/core';
import { SctToastService } from '../services/sct-toast.service';
import { NoteService } from '../note/note.service';
import { PermissionType } from '@app/core/model/constants';
import { UsersService } from '@app/business/users/users.service';
import { SettingsService } from '@app/business/sites/analytics-settings/settings/service/settings.service';
import moment from 'moment';

@Component({
	selector: 'app-noc-note',
	templateUrl: './noc-note.component.html',
	styleUrls: ['./noc-note.component.css']
})
export class NOCNoteComponent implements OnInit {
	@Input() mac_address: any;
	@Input() site_id: number = 0;
	note_text: string = '';
	showing_note: string = '';
	readPermission = PermissionType.READ;
	writePermission = PermissionType.WRITE;

	note: {
		insertion_date: any
		last_connection_time_note: string,
		user_note: string
	} = {
		insertion_date: null,
		last_connection_time_note: '',
		user_note: ''
	};
	formattedNote = '';
	formattedDate: string = '';

	editNoteDialog: SCTDialog = {
		header: this.translateService.instant('g.notes'),
		position: 'center',
		showCloseIcon: true,
		width: '50vw',
		callback: (action: SCTDialogAction) => {
			this.editNoteDialog.visible = false;
			if (action === SCTDialogAction.CANCEL) {
				return;
			}
			this.updateNOCNote();
		}
	};

	constructor(
		private translateService: TranslateService,
		public notificationMessageService: SctToastService,
		public noteService: NoteService,
		public usersService: UsersService,
		public settingsService: SettingsService
	){}

	ngOnInit(): void {
		this.getNOCNote();
	}

	getNOCNote() {
		this.noteService.getNOCNote(this.mac_address, this.site_id).subscribe((data: any) => {
			if(!data.note)
				return;

			const { user_note, last_connection_time_note, insertion_date } = data.note;
			this.note = { ...this.note, user_note, last_connection_time_note, insertion_date };
			this.note_text = user_note;

			this.updateFormattedNote();
			this.updateFormattedDate();
		})
	}

	updateFormattedNote() {
		const noteList = []
		if (this.note.user_note != '')
			noteList.push(this.note.user_note)

		if (this.note.last_connection_time_note != '')
			noteList.push(this.note.last_connection_time_note)

		this.formattedNote = noteList.join(' / ');
	}

	updateFormattedDate(timeNow = false) {
		if (timeNow)
			return this.formattedDate = moment(new Date().getTime()).format("LLLL");

		if (!this.note.insertion_date)
			return this.formattedDate = '--/--/--';

		return this.formattedDate = moment((new Date(this.note.insertion_date).getTime())).format("LLLL");
	}

	showModal() {
		if (this.usersService.hasAccessPermission(null, 'noc', PermissionType.WRITE))
			this.editNoteDialog.visible = true;
	}

	updateNOCNote() {
		this.noteService.updateNOCNote(this.mac_address, this.note_text, this.site_id).subscribe((data: any) => {
			if (data.err)
				return this.notificationMessageService.showMessage(this.translateService.instant('g.invalid_input'));

			this.note.user_note  = this.note_text;
			this.updateFormattedNote();
			this.updateFormattedDate(true);
			this.editNoteDialog.visible = false;
		})
	}
}
