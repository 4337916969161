import { GatewayService } from '@app/core/services/gateway/gateway.service';
import { Injectable } from '@angular/core';
import { CustomHttpParams } from '@app/shared/loader/custom-httpParam';

@Injectable({ providedIn: 'root' })
export class DeviceManagementService {

	constructor(
		private gateway: GatewayService
	) { }


	getSiteDevices(siteId: number) {
		return this.gateway.get(`devices/site-devices/${siteId}`);
	}

	updateDevices(mac_address: string, data: any, site_id: number) {
		return this.gateway.post(`devices/updateDevice`, { ...data, mac_address, site_id });
	}

	createDevices(data: any) {
		return this.gateway.post(`devices/addDevice`, data);
	}

	deleteDevice(data: any) {
		return this.gateway.post('devices/deleteDevices', data);
	}

	hardDeleteDevice(macAddress: string) {
		return this.gateway.delete('devices/hard-delete/'.concat(macAddress));
	}

	getDeviceOwners(macAddress: string[], options?: { allowEmpty?: boolean, getSCTInventorySite?: boolean | undefined }) {
		return this.gateway.post('devices/getDeviceOwners/', { deviceId: macAddress, options });
	}

	getPendingDeviceOwners() {
		return this.gateway.post('devices/getPendingDeviceOwners/', {});
	}

	approveMoveRequest(requestIds: number | number[], options?: { changeSetupDone?: boolean, setupDoneDevices?: string[] }) {
		return this.gateway.post('deviceManagement/approveMoveRequest', { requestIds, options });
	}

	getMoveRequests() {
		return this.gateway.post('deviceManagement/getMoveRequests', {});
	}

	rejectMoveRequest(requestId: number | number[]) {
		return this.gateway.post('deviceManagement/rejectMoveRequest', { requestId });
	}

	getPendingCustomers(options?: { customerId?: number, getReqUser?: boolean, getApproved?: boolean }) {
		return this.gateway.post('deviceManagement/getPendingCustomers', { options });
	}

	approveCustomerRequest(customerId: number, customerInfo: any) {
		return this.gateway.post('deviceManagement/approveCustomerRequest', { customerId, customerInfo });
	}

	rejectCustomerRequest(requestId: number) {
		return this.gateway.post('deviceManagement/rejectCustomerRequest', { requestId });
	}

	getCustomerLinkedDevices(customerId: number) {
		return this.gateway.post('deviceManagement/getCustomerLinkedDevices', { customerId });
	}

	mapToApprovedCustomer(customerId: number, mapToCustomerId: number) {
		return this.gateway.post('deviceManagement/mapToApprovedCustomer', { customerId, mapToCustomerId });
	}

	getPendingSites(options?: { siteId?: number, getReqUser?: boolean }) {
		return this.gateway.post('deviceManagement/getPendingSites', { options });
	}

	approveSiteRequest(siteId: number, siteInfo: any, customer: any = null) {
		return this.gateway.post('deviceManagement/approveSiteRequest', { siteId, siteInfo, customer });
	}

	rejectSiteRequest(requestId: number) {
		return this.gateway.post('deviceManagement/rejectSiteRequest', { requestId });
	}

	mapToApprovedSite(siteId: number, mapToSiteId: number) {
		return this.gateway.post('deviceManagement/mapToApprovedSite', { siteId, mapToSiteId });
	}

	getSiteLinkedDevices(siteId: number) {
		return this.gateway.post('deviceManagement/getSiteLinkedDevices', { siteId });
	}

	checkDeviceTestingTimers(mac_address: string, options?: { checkShipmentOnly?: boolean, receivingShipmentStarted?: boolean, token?: string, cm_po_number?: string, timeZoneDiff?: number }) {
		return this.gateway.post('devices/check-device-testing-timers', { mac_address, options }, new CustomHttpParams({ noUIBlock: true }));
	}

	doReceiveShipment(mac_address: string, options?: { checkShipmentOnly?: boolean, noUIBlock?: boolean, token?: string, cm_po_number?: string }) {
		return this.gateway.post('devices/do-receive-shipment', { mac_address, options }, new CustomHttpParams({ noUIBlock: options?.noUIBlock }));
	}

	moveDeviceToRMA(mac_address: string, rma_no: string) {
		return this.gateway.post('devices/move_to_rma', { mac_address, rma_no });
	}

	getReceiveShipmentHistory() {
		return this.gateway.get('devices/get-receive-shipment-history');
	}

	getReceiveShipmentResult(id: number) {
		return this.gateway.get('devices/get-receive-shipment-result', { id });
	}

	checkDeviceFWStatus(deviceIds: string[]) {
		return this.gateway.post('devices/checkDeviceFWStatus', { deviceIds }, new CustomHttpParams({ noUIBlock: true }));
	}

	shipOutDevices(formData: FormData) {
		return this.gateway.post('/deviceManagement/shipOutDevices', formData);
	}

	checkDeviceInventory(mac_address: string) {
		return this.gateway.get(`/devices/check-inventory-devices/${mac_address}`, new CustomHttpParams({ noUIBlock: true }));
	}

	replaceDevice(sourceDeviceMac: string, destinationDeviceMac: string, changeDate: number) {
		return this.gateway.post('/deviceManagement/replace', { sourceDeviceMac, destinationDeviceMac, changeDate });
	}

	getReplacementRequests() {
		return this.gateway.post('deviceManagement/getReplacementRequests', {});
	}

	approveReplacementRequest(requestId: number) {
		return this.gateway.post('deviceManagement/approveReplacementRequest', { requestId });
	}

	rejectReplacementRequest(requestId: number) {
		return this.gateway.post('deviceManagement/rejectReplacementRequest', { requestId });
	}

	getBilledKWHRsValueAndUnit(value: number = 0) {
		if (value < 900)
			return `${value.toFixed(2)} kWh`;

		if (value < 900000) {
			value /= 1000;
			return `${value.toFixed(2)} MWh`;
		}

		value /= 1000000;
		return `${value.toFixed(2)} GWh`;
	}

	uploadTestingFw(form: FormData) {
		return this.gateway.post('deviceManagement/uploadFw', form);
	}

	pushTestingFw(macAddress: string, fwVersion: number) {
		return this.gateway.post('deviceManagement/pushTestingFw', { mac_address: macAddress, version: fwVersion });
	}

	getRmaDevices(options: any) {
		return this.gateway.get('deviceManagement/rma-devices', options)
	}

	moveFromRma(data: any) {
		return this.gateway.post('deviceManagement/move-from-rma', data);
	}

	getTestingFwVersionList(deviceType: number, options?: any) {
		return this.gateway.post('deviceManagement/getTestingFwVersionList', { deviceType, options })
	}

	checkDeviceTestingFw(macAddress: string) {
		return this.gateway.get(`devices/check-device-testing-fw/${macAddress}`, new CustomHttpParams({ noUIBlock: true }));
	}

	addAttachmentToShipmentHistory(id: number, form: FormData) {
		return this.gateway.post(`deviceManagement/add-attachment-to-shipment-history/${id}`, form);
	}

	deleteFWFile(version: number, type: number) {
		return this.gateway.delete('deviceManagement/deleteFWFile', { version, type })
	}

	getUpdateFirmwareLog(filter: { lastId: number, firstId: number, limit: number, isBack: boolean, filter: any }) {
		return this.gateway.get('deviceManagement/update-firmware-log', filter);
	}

	getSimData(iccid: string) {
		return this.gateway.get('deviceManagement/get-sim-data', { iccid });
	}
}
