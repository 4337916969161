import { Injectable } from '@angular/core';
import { Device } from '@app/business/sites/site.model';
import { UsersService } from '@app/business/users/users.service';
import { deviceTypes, PermissionType, SCT_INVENTORY_SITE_ID } from '@app/core/model/constants';
import { GatewayService } from '@app/core/services/gateway/gateway.service';
import { LoaderInterceptorService } from '@app/core/services/loader-interceptor/loader-interceptor.service';
import { SCTFormBuilderField } from 'sct-framework/form/projects/form/src/lib/form-builder.model';
import { CustomHttpParams } from '@app/shared/loader/custom-httpParam';
import { SCTDialog, SCTDialogAction } from '@app/shared/sct-dialog/sct-dialog.model';
import { CommonDataService } from '@app/shared/services/common-data.service';
import { SctToastService } from '@app/shared/services/sct-toast.service';
import { calcVoltageReference, getDeviceTypeBySerialNumber, toFixedWithoutRounding } from '@app/shared/services/utilities';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import zones from '../../../../../../../../shared/zones.json';
import { formFields as threePhaseMeteringModel, formFields1 as threePhaseMeteringModel1 } from '../three-phase-metering/three-phase-metering.model';
import { CommonService } from '@app/shared/services/common.service';
import * as _ from 'lodash';
import { DevicesService } from '@app/business/sites/devices.service';
@Injectable({
	providedIn: 'root'
})
export class SettingsService {
	extraInfo: any;
	deviceInfo: any;
	simInfo: any = {};
	isDeviceConnected = false;
	changesStack: any;
	devicesDropdownList!: Device[];
	customerId!: number;
	siteId!: number;
	dirtyData: any;
	waitTimeout = 45;
	saveOptionsDialog: SCTDialog = {
		header: 'settings_device_settings.save_device_settings',
		isModal: true,
		callback: (action: SCTDialogAction) => {
			let stage = 'cancel';
			if (action === SCTDialogAction.SAVE)
				stage = 'direct';
			this.saveDeviceSettings(stage);
			this.saveOptionsDialog.visible = false;
		},
		width: '500px'
	};

	removeChangesStack: SCTDialog = {
		header: 'g.confirm_action',
		width: '500px',
		callback: (action: SCTDialogAction) => {
			if (action != SCTDialogAction.CANCEL) {
				this.removeChangesStack.visible = false;
				return this.deleteQueueChanges(this.removeChangesStack.data, true);
			}
			this.removeChangesStack.visible = false
			return;
		}
	};
	public devicesSubject = new BehaviorSubject<Device[]>([]);
	isMultiSettings: boolean = false;
	private dataSubject = new BehaviorSubject<Object[]>([]);
	data = this.dataSubject.asObservable();
	macAddress!: string;
	invalidFields: any = {};
	restartDeviceDialog!: SCTDialog;
	deviceTags: any;
	hideMultiRestarDevice: boolean = false;
	formCallbackFunction: Function = () => { };

	private permissionsOfCurrentSiteSbj = new BehaviorSubject<Object>({});
	permissionsOfCurrentSite = this.permissionsOfCurrentSiteSbj.asObservable();

	public isChangesApplied = new BehaviorSubject<boolean>(false); //Saved in DB or Queue applied
	isApplied = this.isChangesApplied.asObservable();

	public isSavedDone = new BehaviorSubject<boolean>(false);
	isSaved = this.isSavedDone.asObservable();


	staticIpFields = [
		'static_ethernet_ip',
		'static_wifi_ip',
		'static_ethernet_gw',
		'static_wifi_gw',
		'static_ethernet_netmask',
		'static_wifi_netmask'
	];

	lteCellularBands = [
		{ text: "BAND B1", value: 0x01 }, // 1
		{ text: "BAND B2", value: 0x02 }, // 2
		{ text: "BAND B3", value: 0x04 }, // 4
		{ text: "BAND B4", value: 0x08 }, // 8
		{ text: "BAND B5", value: 0x10 }, // 16
		{ text: "BAND B8", value: 0x20 }, // 32
		{ text: "BAND B12", value: 0x40 }, // 64
		{ text: "BAND B13", value: 0x80 }, // 128
		{ text: "BAND B18", value: 0x100 }, // 256
		{ text: "BAND B19", value: 0x200 }, // 512
		{ text: "BAND B20", value: 0x400 }, // 1048
		{ text: "BAND B25", value: 0x800 }, // 2096
		{ text: "BAND B26", value: 0x1000 },
		{ text: "BAND B27", value: 0x2000 },
		{ text: "BAND B28", value: 0x4000 },
		{ text: "BAND B66", value: 0x10000 },
		{ text: "BAND B85", value: 0x80000 }
	];

	nbiotCellularBands = [
		{ text: "BAND B1", value: 0x01 }, // 1
		{ text: "BAND B2", value: 0x02 }, // 2
		{ text: "BAND B3", value: 0x04 }, // 4
		{ text: "BAND B4", value: 0x08 }, // 8
		{ text: "BAND B5", value: 0x10 }, // 16
		{ text: "BAND B8", value: 0x20 }, // 32
		{ text: "BAND B12", value: 0x40 }, // 64
		{ text: "BAND B13", value: 0x80 }, // 128
		{ text: "BAND B18", value: 0x100 }, // 256
		{ text: "BAND B19", value: 0x200 }, // 512
		{ text: "BAND B20", value: 0x400 }, // 1048
		{ text: "BAND B25", value: 0x800 }, // 2096
		{ text: "BAND B28", value: 0x4000 },
		{ text: "BAND B66", value: 0x10000 },
		{ text: "BAND B71", value: 0x100000 },
		{ text: "BAND B85", value: 0x80000 }
	];

	objectFields: string[] = ['mobile_master', 'mobile_network', 'router_network', 'online_network', 'ade90xx_settings'];

	cellularIpFields = ['static_cellular_ip', 'static_cellular_gw', 'static_cellular_netmask'];
	cellularBandsFields = ['lte_cellular_bands', 'nbiot_cellular_bands'];

	restartRequired: boolean = false;

	/**
	 * tab -> field -> permission, forType
	*/

	listOfFieldsInEachTab: any = {
		'device-info': {
			setup_key: {
				permission: 'device.setup_key',
			},
			memory_signature: {
				permission: 'device.memory_signature',
			},
			last_saved_user_id: {
				permission: 'device.last_saved_user_id',
			},
			mac_address: {
				permission: 'device.mac_address',
			},
			serial_number: {
				permission: 'device.serial_number',
			},
			installation_date: {
				permission: 'device.installation_date',
				canViewOnMultiSetting: true
			},
			hw_version: {
				permission: 'device.hw_version',
			},
			device_password: {
				permission: 'device.device_password',
			},
			power_view_id: {
				permission: 'device.power_view_id',
			},
			power_view_sn: {
				permission: 'device.power_view_sn',
			},
			load_type: {
				permission: 'device.load_type',
				canViewOnMultiSetting: true
			},
			in_test_mode: {
				permission: 'in_test_mode',
			},
			setup_done: {
				permission: 'device.setup_done',
			},
			setup_reset_data: {
				permission: 'device.setup_reset_data',
			},
			sct_pre_configured: {
				permission: 'device.sct_pre_configured',
			},
			setup_user_id: {
				permission: 'device.setup_user_id',
			},
			setup_time: {
				permission: 'device.setup_time',
			},
			last_change_time: {
				permission: 'device.last_change_time',
			},
			setup_requested_site: {
				permission: 'device.setup_requested_site',
			},
			setup_requested_customer: {
				permission: 'device.setup_requested_customer',
			},
			setup_requested_address: {
				permission: 'device.setup_requested_address',
			},
			setup_replace_mac: {
				permission: 'device.setup_replace_mac',
			},
			fw_version: {
				permission: 'device.fw_version',
			},
			meter_sn: {
				permission: 'device.meter_sn',
			},
			meter_sn_changetime: {
				permission: 'device.meter_sn_changetime',
			},
			subscription_date: {
				permission: 'device.subscription_date',
			},
			unique_mac_address: {
				permission: 'device.unique_mac_address',
			},
			lost_rtc: {
				permission: 'device.lost_rtc',
			},
			ade_90_xx_calibration_status: {
				permission: "device.ade_90_xx_calibration_status"
			},
			latitude: {
				permission: "device.latitude"
			},
			longitude: {
				permission: "device.longitude"
			}
		},
		'device-settings': {
			modbus_enable: {
				permission: "device.modbus_enable",
				forType: deviceTypes.TYPE1
			},
			remote_flash_timer: {
				permission: 'device.remote_flash_timer',
				canViewOnMultiSetting: true
			},
			zone_id: {
				permission: 'device.zone_id',
				canViewOnMultiSetting: true
			},
			enable_rt: {
				permission: 'device.enable_rt',
				canViewOnMultiSetting: true
			},
			rt_log_frequency: {
				permission: 'device.rt_log_frequency',
				canViewOnMultiSetting: true
			},
			single_phase: {
				permission: "device.single_phase",
				forType: deviceTypes.TYPE2
			},
			over_voltage_threshold: {
				permission: "device.over_voltage_threshold"
			},
			over_current_threshold: {
				permission: "device.over_current_threshold"
			},
			under_voltage_threshold: {
				permission: "device.under_voltage_threshold"
			},
			sel_freq: {
				permission: "device.ade90xx_settings.sel_freq",
				forType: deviceTypes.TYPE2
			},
			ct_ratio: {
				permission: "device.ct_ratio",
			},
			reverse_energy_sign: {
				permission: "device.reverse_energy_sign",
			},
			_virtual_service_type: {
				permission: "device._virtual_service_type",
			}
		},
		'networking': {
			'mobile_master.ssid': {
				permission: 'device.mobile_master.ssid',
			},
			'mobile_master.priority': {
				permission: 'device.mobile_master.priority',
			},
			'mobile_master.password': {
				permission: 'device.mobile_master.password',
			},
			'mobile_master.type': {
				permission: 'device.mobile_master.type',
			},
			'mobile_network.ssid': {
				permission: 'device.mobile_network.ssid',
			},
			'mobile_network.priority': {
				permission: 'device.mobile_network.priority',
			},
			'mobile_network.password': {
				permission: 'device.mobile_network.password',
			},
			'mobile_network.type': {
				permission: 'device.mobile_network.type',
			},
			'router_network.ssid': {
				permission: 'device.router_network.ssid',
			},
			'router_network.priority': {
				permission: 'device.router_network.priority',
			},
			'router_network.password': {
				permission: 'device.router_network.password',
			},
			'router_network.type': {
				permission: 'device.router_network.type',
			},
			'online_network.ssid': {
				permission: 'device.online_network.ssid',
				canViewOnMultiSetting: true
			},
			'online_network.priority': {
				permission: 'device.online_network.priority',
				canViewOnMultiSetting: true
			},
			'online_network.bssid': {
				permission: 'device.online_network.bssid',
				canViewOnMultiSetting: true
			},
			'online_network.password': {
				permission: 'device.online_network.password',
				canViewOnMultiSetting: true
			},
			'online_network.type': {
				permission: 'device.online_network.type',
			},
		},
		'networking-settings': {
			remoter_server_port: {
				permission: 'device.remoter_server_port',
			},
			mobile_port: {
				permission: 'device.mobile_port',
			},
			master_port: {
				permission: 'device.master_port',
			},
			reconnect_time: {
				permission: 'device.reconnect_time',
			},
			server_socket_port: {
				permission: 'device.server_socket_port',
			},
			enable_cellular: {
				permission: 'device.enable_cellular',
			},
			cellular_region: {
				permission: 'device.cellular_region',
			},
			static_cellular_ip: {
				permission: 'device.static_cellular_ip',
			},
			static_cellular_gw: {
				permission: 'device.static_cellular_gw',
			},
			static_cellular_netmask: {
				permission: 'device.static_cellular_netmask',
			},
			cell_acces_point_name: {
				permission: 'device.cell_acces_point_name',
			},
			lte_cellular_bands: {
				permission: 'device.lte_cellular_bands',
			},
			nbiot_cellular_bands: {
				permission: 'device.nbiot_cellular_bands',
			},
			cellular_reconnect_time: {
				permission: 'device.cellular_reconnect_time',
			},
			remoter_server_ip: {
				permission: 'device.remoter_server_ip',
			},
			ap_password: {
				permission: 'device.ap_password',
			},
			ap_ssid: {
				permission: 'device.ap_ssid',
			},
			enable_wifi_sta: {
				permission: 'device.enable_wifi_sta',
			},
			enable_wifi_ap_on_start: {
				permission: 'device.enable_wifi_ap_on_start',
			},
			ap_channel: {
				permission: 'device.ap_channel',
			},
			enc_code: {
				permission: 'device.enc_code',
			},
			static_ethernet_ip: {
				permission: 'device.static_ethernet_ip',
			},
			static_wifi_ip: {
				permission: 'device.static_wifi_ip',
			},
			static_ethernet_gw: {
				permission: 'device.static_ethernet_gw',
			},
			static_wifi_gw: {
				permission: 'device.static_wifi_gw',
			},
			static_ethernet_netmask: {
				permission: 'device.static_ethernet_netmask',
			},
			static_wifi_netmask: {
				permission: 'device.static_wifi_netmask',
			},
			multicast_port: {
				permission: 'device.multicast_port',
			},
			enable_ethernet: {
				permission: 'device.enable_ethernet',
				canViewOnMultiSetting: true
			},
			enable_multicast: {
				permission: 'device.enable_multicast',
			},
			multicast_ip: {
				permission: 'device.multicast_ip',
			},
			enable_serversocket: {
				permission: 'device.enable_serversocket',
			},
			energy_acc_threshold_outer: {
				permission: "device.energy_acc_threshold_outer",
			},
			metered_connection: {
				permission: 'device.metered_connection',
			},
			energy_acc_threshold_inner: {
				permission: "device.energy_acc_threshold_inner",
				forType: deviceTypes.TYPE2
			},
			energy_acc_seconds_no_energy: {
				permission: "device.energy_acc_seconds_no_energy",
				forType: deviceTypes.TYPE2
			},
			energy_acc_seconds_valid_energy: {
				permission: "device.energy_acc_seconds_valid_energy",
				forType: deviceTypes.TYPE2
			},
		},
		'connectivity-frequency': {
			cellular_reconnect_time: {
				permission: 'device.revert_time',
			},
		},
		'three-phase-metering': {
			v_a_gain: {
				permission: "device.ade90xx_settings.v_a_gain",
				forType: deviceTypes.TYPE2
			},
			v_b_gain: {
				permission: "device.ade90xx_settings.v_b_gain",
				forType: deviceTypes.TYPE2
			},
			v_c_gain: {
				permission: "device.ade90xx_settings.v_c_gain",
				forType: deviceTypes.TYPE2
			},
			i_a_gain: {
				permission: "device.ade90xx_settings.i_a_gain",
				forType: deviceTypes.TYPE2
			},
			i_b_gain: {
				permission: "device.ade90xx_settings.i_b_gain",
				forType: deviceTypes.TYPE2
			},
			i_c_gain: {
				permission: "device.ade90xx_settings.i_c_gain",
				forType: deviceTypes.TYPE2
			},
			i_n_gain: {
				permission: "device.ade90xx_settings.i_n_gain",
				forType: deviceTypes.TYPE2
			},
			watt_acc: {
				permission: "device.ade90xx_settings.watt_acc",
				forType: deviceTypes.TYPE2
			},
			var_acc: {
				permission: "device.ade90xx_settings.var_acc",
				forType: deviceTypes.TYPE2
			},
			vconsel: {
				permission: "device.ade90xx_settings.vconsel",
				forType: deviceTypes.TYPE2
			},
			iconsel: {
				permission: "device.ade90xx_settings.iconsel",
				forType: deviceTypes.TYPE2
			},
			aigain: {
				permission: "device.ade90xx_settings.aigain",
				forType: deviceTypes.TYPE2
			},
			bigain: {
				permission: "device.ade90xx_settings.bigain",
				forType: deviceTypes.TYPE2
			},
			cigain: {
				permission: "device.ade90xx_settings.cigain",
				forType: deviceTypes.TYPE2
			},
			nigain: {
				permission: "device.ade90xx_settings.nigain",
				forType: deviceTypes.TYPE2
			},
			avgain: {
				permission: "device.ade90xx_settings.avgain",
				forType: deviceTypes.TYPE2
			},
			bvgain: {
				permission: "device.ade90xx_settings.bvgain",
				forType: deviceTypes.TYPE2
			},
			cvgain: {
				permission: "device.ade90xx_settings.cvgain",
				forType: deviceTypes.TYPE2
			},
			airmsos: {
				permission: "device.ade90xx_settings.airmsos",
				forType: deviceTypes.TYPE2
			},
			birmsos: {
				permission: "device.ade90xx_settings.birmsos",
				forType: deviceTypes.TYPE2
			},
			cirmsos: {
				permission: "device.ade90xx_settings.cirmsos",
				forType: deviceTypes.TYPE2
			},
			nirmsos: {
				permission: "device.ade90xx_settings.nirmsos",
				forType: deviceTypes.TYPE2
			},
			avrmsos: {
				permission: "device.ade90xx_settings.avrmsos",
				forType: deviceTypes.TYPE2
			},
			bvrmsos: {
				permission: "device.ade90xx_settings.bvrmsos",
				forType: deviceTypes.TYPE2
			},
			cvrmsos: {
				permission: "device.ade90xx_settings.cvrmsos",
				forType: deviceTypes.TYPE2
			},
			aphcal0: {
				permission: "device.ade90xx_settings.aphcal0",
				forType: deviceTypes.TYPE2
			},
			bphcal0: {
				permission: "device.ade90xx_settings.bphcal0",
				forType: deviceTypes.TYPE2
			},
			cphcal0: {
				permission: "device.ade90xx_settings.cphcal0",
				forType: deviceTypes.TYPE2
			},
			apgain: {
				permission: "device.ade90xx_settings.apgain",
				forType: deviceTypes.TYPE2
			},
			bpgain: {
				permission: "device.ade90xx_settings.bpgain",
				forType: deviceTypes.TYPE2
			},
			cpgain: {
				permission: "device.ade90xx_settings.cpgain",
				forType: deviceTypes.TYPE2
			},
			burden_res: {
				permission: "device.ade90xx_settings.burden_res",
				forType: deviceTypes.TYPE2
			},
			r1: {
				permission: "device.ade90xx_settings.r1",
				forType: deviceTypes.TYPE2
			},
			r2: {
				permission: "device.ade90xx_settings.r2",
				forType: deviceTypes.TYPE2
			},
			ade_90xx: {
				permission: "device.ade90xx_settings.ade_90xx",
				forType: deviceTypes.TYPE2
			},
			power_a_gain: {
				permission: "device.ade90xx_settings.power_a_gain",
				forType: deviceTypes.TYPE2
			},
			power_b_gain: {
				permission: "device.ade90xx_settings.power_b_gain",
				forType: deviceTypes.TYPE2
			},
			power_c_gain: {
				permission: "device.ade90xx_settings.power_c_gain",
				forType: deviceTypes.TYPE2
			},
			vConsel: {
				permission: "device.vconsel",
				forType: deviceTypes.TYPE1
			},
			iConsel: {
				permission: "device.iconsel",
				forType: deviceTypes.TYPE1
			},
		},
		'cellular-settings': {
			cellular_rssi: {
				permission: "device.cellular_rssi"
			},
			cellular_connected: {
				permission: "device.cellular_connected"
			},
			cellular_longitude: {
				permission: "device.cellular_longitude"
			},
			cellular_latitude: {
				permission: "device.cellular_latitude"
			},
			cellular_imsi: {
				permission: "device.cellular_imsi"
			},
			cellular_imei: {
				permission: "device.cellular_imei"
			},
			cellular_iccid: {
				permission: "device.cellular_iccid"
			},
			cellular_cereg_val: {
				permission: "device.cellular_cereg_val"
			},
			cellular_failure_type: {
				permission: "device.cellular_failure_type"
			},
			cellular_access_technology_selected: {
				permission: "device.cellular_access_technology_selected"
			},
			operator_in_numeric_format: {
				permission: "device.operator_in_numeric_format"
			},
			cellular_band: {
				permission: "device.cellular_band"
			},
			cellular_channel: {
				permission: "device.cellular_channel"
			},
			cellular_ip: {
				permission: "device.cellular_ip"
			},
			cellular_gateway: {
				permission: "device.cellular_gateway"
			},
			cellular_netmask: {
				permission: "device.cellular_netmask"
			},
			cellular_detailed_failure_flags: {
				permission: "device.cellular_detailed_failure_flags"
			},
			cell_module_id: {
				permission: "device.cell_module_id"
			},
			cell_fw_version: {
				permission: "device.cell_fw_version"
			},
		},
		'devices-included-reports': {
			is_feeder_meter: {
				permission: "device.is_feeder_meter"
			},
			is_meter_subtracting: {
				permission: "device.is_meter_subtracting"
			}
		},
		'event-type-settings': {
			event_logic: {
				permission: "device.event_logic"
			},
			idle_to_consume_power: {
				permission: "device.idle_to_consume_power"
			},
			idle_to_consume_timer: {
				permission: "device.idle_to_consume_timer"
			},
			idle_to_generate_power: {
				permission: "device.idle_to_generate_power"
			},
			idle_to_generate_timer: {
				permission: "device.idle_to_generate_timer"
			},
			consume_to_idle_power: {
				permission: "device.consume_to_idle_power"
			},
			consume_to_idle_timer: {
				permission: "device.consume_to_idle_timer"
			},
			consume_to_generate_power: {
				permission: "device.consume_to_generate_power"
			},
			consume_to_generate_timer: {
				permission: "device.consume_to_generate_timer"
			},
			generate_to_idle_power: {
				permission: "device.generate_to_idle_power"
			},
			generate_to_idle_timer: {
				permission: "device.generate_to_idle_timer"
			},
			generate_to_consume_power: {
				permission: "device.generate_to_consume_power"
			},
			generate_to_consume_timer: {
				permission: "device.generate_to_consume_timer"
			},
		}
	}

	readonly lineToNeutral = 1;
	readonly lineToLine = 0;
	readonly DEVICE_SETTING_TAB_NAME = 'device-settings';

	remoter_server_ip = [
		{ id: 'production', text: this.translateService.instant('env.production'), data: { port: 9313, encCode: 2, domain: this.commonData.productionDomain } },
		{ id: 'staging', text: this.translateService.instant('env.staging'), data: { port: 9314, encCode: 2, domain: this.commonData.stagingDomain } },
		{ id: 'development', text: this.translateService.instant('env.dev'), data: { port: 9313, encCode: 1, domain: this.commonData.developmentDomain } },
		{ id: 'custom', text: this.translateService.instant('env.custom'), data: { port: 9313, encCode: 1, domain: '' } }
	];

	constructor(
		private gateway: GatewayService,
		private usersService: UsersService,
		private sctToastService: SctToastService,
		private translateService: TranslateService,
		public commonData: CommonDataService,
		private loader: LoaderInterceptorService,
		private commonService: CommonService,
		private validationService: ValidationService,
	) { }

	get zonesForDropDown(): any[] {
		return zones.map((zone) => ({
			name: zone.display_name,
			key: zone.id
		}));
	}

	getTimeZone(index: number) {
		if (index > zones.length - 1) {
			return {};
		}
		return zones[index];
	}

	getSiteDevicesShortInfo(siteId: number) {
		return this.gateway.get(`devices/for-dropdown/${siteId}`);
	}

	getQuickView(macAddress: string, noUiBlock?: boolean) {
		return this.gateway.get(`devices/get-quick-view/${macAddress}`, { noUiBlock });
	}

	getInfo(macAddress: string) {
		return this.gateway.get(`devices/device-info/${macAddress}`);
	}

	getDeviceInfo(customerId: number, siteId: number, macAddress: string, options?: { get_changes_stack?: boolean }) {
		return this.gateway.post(`devices/getDeviceInfo`, { macAddress, options, customerId, siteId });
	}

	getShippingConnectivityData(devicesIds: string[]) {
		return this.gateway.get(`devices/getDeviceShippingConnectivity/${devicesIds}`);
	}

	updateShippingConnectivityData(macAddress: string, action: "extend" | "cancel" | "renew") {
		return this.gateway.post(`devices/updateDeviceShippingConnectivity`, { macAddress, action });
	}

	saveDeviceInfo(macAddress: string, changes: any, stage: string, wait: number = 0, getChangesStack = false, deviceTab?: string) {
		return this.gateway.post(`devices/saveDeviceSettings`, { macAddress, changes, stage, wait, getChangesStack, deviceTab });
	}

	resetHours(timestamp: string | number | Date) {
		let date = new Date((new Date(+timestamp * 1000)).setUTCHours(0, 0, 0, 0));
		return (date.getTime() / 1000);
	}

	saveData(data: any, options: { deviceTab?: string, restartRequired?: boolean, formCallback?: Function, hideMultiRestarDevice?: boolean } = {}) {
		const resetHoursFields = ["installation_date","setup_time"];

		resetHoursFields.forEach((dateField) => {
			if (data[dateField])
				data[dateField] = this.resetHours(data[dateField]);
		})

		if (options.hideMultiRestarDevice)
			this.hideMultiRestarDevice = options.hideMultiRestarDevice;

		delete data._virtual_service_type;
		this.isSavedDone.next(false);

		if (options.formCallback)
			this.formCallbackFunction = options.formCallback;

		this.dirtyData = {}
		if (!data || !Object.keys(data).length) {
			this.sctToastService.showMessage('translate|g.no_changes_found', 'warn');
			return;
		}

		if (data.remoter_server_ip_options && data.remoter_server_ip_options.domain !== '') {
			data.remoter_server_ip = data.remoter_server_ip_options.domain;
			data.remoter_server_port = this.remoter_server_ip.filter((item) => data.remoter_server_ip_options.domain === item.data.domain)[0].data.port;
		}

		this.dirtyData = data;
		if (this.isMultiSettings)
			return this.saveDeviceSettings('queue');
		if (this.usersService.hasAccessFunction('allow_device_db_save')) {
			this.saveOptionsDialog.data = options.deviceTab;
			this.restartRequired = !!options.restartRequired;
			return this.saveOptionsDialog.visible = true;
		}
		return this.saveDeviceSettings('queue_and_wait', { deviceTab: options.deviceTab, restartRequired: options.restartRequired });
	}

	saveDeviceSettings(stage: string, options: { deviceTab?: string, restartRequired?: boolean } = {}) {
		if (!this.dirtyData || !Object.keys(this.dirtyData).length)
			return;

		const originalStage = stage;
		let restartRequired = false;
		if (this.usersService.hasAccessFunction('allow_device_db_save') && this.saveOptionsDialog.visible) {
			restartRequired = this.restartRequired;
			options.deviceTab = this.saveOptionsDialog.data;
			this.saveOptionsDialog.visible = false;
		}

		if (stage == 'cancel')
			return;
		const macAddress = this.macAddress;

		if (this.isMultiSettings) {
			let deviceIds = this.macAddress.split(',');
			this.saveMultiDevicesSettings(deviceIds, this.dirtyData).subscribe((response: any) => {
				this.handleSaveResponse(response, originalStage, options.deviceTab);
			});
		} else {
			let command = stage;

			let wait = 0;
			if (stage == 'queue_and_wait') {
				wait = this.waitTimeout;
				stage = 'queue';
			}

			let getChangesStack = false;

			if (stage == 'queue') {
				getChangesStack = true;
			}

			this.restartRequired = !!(options.restartRequired !== undefined ? options.restartRequired : restartRequired);
			this.saveDeviceInfo(macAddress, this.dirtyData, stage, wait, getChangesStack, options.deviceTab).subscribe((res: any) => {
				let deviceResponse = res;
				if (res.api_status == this.commonService.isDisconnectedWithWaitResponse)
					deviceResponse = res.response;

				this.handleSaveResponse(res, originalStage, options.deviceTab, deviceResponse);
				let isSaved = command == 'direct' || (command == 'queue_and_wait' && res.api_status != this.commonService.isDisconnectedWithWaitResponse);
				if (isSaved) {
					if (this.dirtyData.power_view_id) {
						const changeStack = [macAddress, this.dirtyData.power_view_id];
						this.devicesSubject.next(changeStack);
					}
				}
				this.isChangesApplied.next(isSaved);
				this.formCallbackFunction(isSaved);
			});
		}
	}

	handleSaveResponse(apiResponse: any, stage: string, deviceTab?: string, deviceResponse?: any) {
		deviceResponse = deviceResponse || apiResponse;

		// both 0 and 1 are Cellular Only
		if (deviceResponse.config_info?.metered_connection == 0)
			deviceResponse.config_info.metered_connection = 1;

		if (apiResponse.response_status == this.loader.resStatus.api_timeout || apiResponse.api_status == this.commonService.isDisconnectedWithWaitResponse) {
			// if the api timed-out OR if wait sent and the device is disconnected.

			this.sctToastService.messageService.add({ severity: 'warn', summary: this.translateService.instant('g.warning'), detail: this.translateService.instant('settings_device_settings.queue_and_wait_timeout_msg') });
			this.handleSuccessSaveSettingsResponse();
		} else if (deviceResponse.invalid_fields)
			return this.sctToastService.messageService.add({ severity: 'error', summary: this.translateService.instant('g.error'), detail: this.translateService.instant('g.invalid_input') });
		else {
			this.handleSuccessSaveSettingsResponse();
			if (stage == 'queue')
				this.sctToastService.messageService.add({ severity: 'warn', summary: this.translateService.instant('g.warning'), detail: this.translateService.instant('settings_device_settings.changes_queued') });
			else {
				this.sctToastService.messageService.add({ severity: "success", summary: this.translateService.instant('g.success'), detail: this.translateService.instant('g.global_success_msg') });
			}
		}

		if (deviceResponse.changes_stack)
			this.changesStack = deviceResponse.changes_stack;

		this.onAfterSave(deviceResponse.config_info, deviceTab);
		this.deviceInfo = deviceResponse.config_info;
		this.restartRequired = false;
		this.isSavedDone.next(true);
		this.formatRtLogFrequencyField(false, this.deviceInfo.rt_log_frequency);
		this.updateRemoterServerIP();
	}

	onAfterSave(config_info: any, deviceTab?: string) {
		if (!config_info)
			return;

		if (this.DEVICE_SETTING_TAB_NAME === deviceTab && config_info.voltage_reference === this.lineToLine) {
			config_info.under_voltage_threshold = calcVoltageReference(config_info.under_voltage_threshold, 'multi');
			config_info.over_voltage_threshold = calcVoltageReference(config_info.over_voltage_threshold, 'multi');
		}

		if (!this.usersService.getCurrentUser().is_admin) {
			config_info.fw_version = toFixedWithoutRounding(config_info.fw_version || 0, 2);
		}

		for (let field of this.cellularIpFields) {
			if (config_info[field])
				config_info[field] = this.commonService.int2ip(config_info[field]);
		}
		this.formatBandsFields(config_info);
		this.formatChangesStack();
	}

	handleSuccessSaveSettingsResponse() {
		if (!this.restartRequired)
			return;
		this.permissionsOfCurrentSite.subscribe((data: any) => {
			if (this.usersService.hasAccessPermission(data, 'actions.restartDevice') && !this.hideMultiRestarDevice)
				this.restartDeviceDialog.visible = true;
		});
	}

	formatBandsFields(deviceInfo?: any) {
		deviceInfo = deviceInfo || this.deviceInfo;
		for (let bandField of this.cellularBandsFields) {
			let bandFieldValues = deviceInfo[bandField];

			if (!bandFieldValues || Array.isArray(bandFieldValues))
				continue;

			let cellularBandArray = [];
			let changeStackBandArray = [];
			const cellularBands = _.uniqBy([...this.lteCellularBands, ...this.nbiotCellularBands], 'value');

			for (let field of cellularBands) {
				if ((bandFieldValues & field.value) != 0)
					cellularBandArray.push(field.value);

				if (bandField in this.changesStack) {
					if ((this.changesStack[bandField][this.changesStack[bandField].length - 1][0] & field.value) != 0) // last change stack
						changeStackBandArray.push(field.text + '  ');
				}
			}

			deviceInfo[bandField] = +bandFieldValues;
			deviceInfo[`${bandField}_formatted`] = cellularBandArray;

			if (bandField in this.changesStack) {
				this.changesStack[bandField][this.changesStack[bandField].length - 1][0] = changeStackBandArray; // TODO: This will handle last change. edit it after adding merge stack changes ticket.
				this.changesStack[`${bandField}_formatted`] = this.changesStack[bandField];
			}
		}
	}

	formatChangesStack() {
		for (let field of this.cellularIpFields) {
			if (field in this.changesStack)
				this.changesStack[field][this.changesStack[field].length - 1][0] = this.commonService.int2ip(this.changesStack[field][this.changesStack[field].length - 1][0])
			// TODO: This will handle last change. edit it after adding merge stack changes ticket.
		}

		if ('rt_log_frequency' in this.changesStack)
			for (let index in this.changesStack['rt_log_frequency']) {
				let rtLogFrequency = this.changesStack['rt_log_frequency'][index][0];
				if (typeof rtLogFrequency == 'number') {
					this.changesStack['rtLogFrequency'] = this.changesStack['rt_log_frequency'];
					this.changesStack['rt_log_frequency'][index][0] = this.formatRtLogFrequencyField(true, rtLogFrequency);
				}
			}
	}

	formatRtLogFrequencyField(getResultText: boolean, rtLogFrequency: number) {
		const rtLogFrequencyBin = this.validationService.decimal2binary(rtLogFrequency, 8);
		const rtLogFrequencyUnit = +rtLogFrequencyBin[0];
		const rt_log_frequency = this.validationService.binary2decimal(rtLogFrequencyBin.slice(1));

		if (getResultText)
			return `${rt_log_frequency} ${rtLogFrequencyUnit ? this.translateService.instant('g.minutes_value') : this.translateService.instant('g.seconds_value')}`;

		this.deviceInfo.rtLogFrequencyUnit = rtLogFrequencyUnit;
		this.deviceInfo.rtLogFrequency = rt_log_frequency;
		return;
	}

	updateRemoterServerIP() {
		switch (this.deviceInfo.remoter_server_ip) {
			case this.commonData.productionDomain:
				this.deviceInfo.remoter_server_ip_options = this.remoter_server_ip[0].data;
				break;
			case this.commonData.stagingDomain:
				this.deviceInfo.remoter_server_ip_options = this.remoter_server_ip[1].data;
				break;
			case this.commonData.developmentDomain:
				this.deviceInfo.remoter_server_ip_options = this.remoter_server_ip[2].data;
				break;
			default:
				this.deviceInfo.remoter_server_ip_options = this.remoter_server_ip[3].data;
				break;
		}
	}

	setPermissionsOfCurrentSite(data: Object) {
		this.permissionsOfCurrentSiteSbj.next(data);
	}

	getDeviceType() {
		return getDeviceTypeBySerialNumber(this.deviceInfo?.serial_number);
	}

	checkIfAllFieldIsHidden(tab: 'devices-included-reports' | 'device-info' | 'device-settings' | 'networking-settings' | 'networking' | 'three-phase-metering' | 'cellular-settings' | 'connectivity-frequency' | any, permissions: any) {
		const tabFields: any = this.listOfFieldsInEachTab[tab];
		const deviceType = this.getDeviceType();
		let allFieldsAreHidden = true;
		for (const field in tabFields) {
			const access: PermissionType = this.usersService.checkFieldPermissions(tabFields[field].permission, permissions);
			if (access !== PermissionType.NO_ACCESS && ((!this.isMultiSettings && (tabFields[field]?.forType === deviceType || tabFields[field]?.forType === undefined)) || (this.isMultiSettings && tabFields[field]?.canViewOnMultiSetting))) {
				allFieldsAreHidden = false;
				break;
			}
		}
		return allFieldsAreHidden;
	}

	checkIfAllFieldIsNotEditable(tab: 'device-info' | 'device-settings' | 'networking-settings' | 'networking' | 'three-phase-metering' | 'cellular-settings', permissions: any) {
		const tabFields: any = this.listOfFieldsInEachTab[tab];
		const deviceType = this.getDeviceType();
		let hide = true;
		for (const field in tabFields) {
			const access: PermissionType = this.usersService.checkFieldPermissions(tabFields[field].permission, permissions);
			if (access === PermissionType.WRITE && (tabFields[field]?.forType === deviceType || !tabFields[field]?.forType)) {
				hide = false;
				break;
			}
		}
		return hide;
	}

	getFormFields(tab: 'three-phase-metering') {
		let model: SCTFormBuilderField[] = [];
		if (tab == 'three-phase-metering')
			model = this.getDeviceType() === deviceTypes.TYPE2 ? threePhaseMeteringModel : threePhaseMeteringModel1;

		if (model.length === 0)
			return [];

		const availableFields = [];
		for (let field of model) {
			if (field.isFormGroup) {
				const availableSubFields = [];
				for (let subField of field.fields || []) {
					if (this.checkIfFieldIsAvailableForDeviceType(tab, subField.id || subField.name))
						availableSubFields.push(subField);
				}
				if (availableSubFields.length) {
					availableFields.push({ ...field, fields: availableSubFields });
				}
				continue;
			}
			if (this.checkIfFieldIsAvailableForDeviceType(tab, field.id || field.name))
				availableFields.push(field);
		}
		return availableFields;
	}


	deleteQueueChanges(data: any, first?: boolean) {
		if (!first) {
			this.removeChangesStack.data = data;
			this.removeChangesStack.visible = true;
			return;
		}
		const macAddress = this.deviceInfo.mac_address;
		const commandId = this.removeChangesStack.data.commandId;
		const fieldName = this.handleChangesStackChanges(this.removeChangesStack.data);
		return this.gateway.post('devices/cancelQueuedChanges', { commandId, macAddress, fieldName }, new CustomHttpParams({ noUIBlock: false })).subscribe((res: any) => {
			switch(res.api_status) {
				case 1:
					this.sctToastService.showMessage('globalSuccessMsg');
					break;
				default:
					this.sctToastService.showMessage('globalErrMsg');
					break;
			}
		});
	}

	handleChangesStackChanges(data: any) {
		let fieldName = data.path[0];
		if (fieldName == 'rtLogFrequency') {
			delete this.changesStack[fieldName];
			return fieldName = 'rt_log_frequency';
			
		}

		if (['lte_cellular_bands_formatted', 'nbiot_cellular_bands_formatted'].includes(fieldName)) {
			delete this.changesStack[fieldName];
			return fieldName.replace('_formatted', '');
		}
		if (this.objectFields.includes(fieldName)) {
			delete this.changesStack[data.path[0]][data.path[1]];
			return data.path;
		}
		delete this.changesStack[fieldName];
		return fieldName;
	}

	private checkIfFieldIsAvailableForDeviceType(tab: 'three-phase-metering', fieldName: string) {
		const tabFields: any = this.listOfFieldsInEachTab[tab];
		const deviceType = this.getDeviceType();
		return (tabFields[fieldName]?.forType === deviceType || !tabFields[fieldName]?.forType);
	}

	getMultiDevicesInfo(deviceIds: string[]) {
		return this.gateway.post('devices/getMultiDevicesInfo', { deviceIds });
	}

	saveMultiDevicesSettings(deviceIds: string[], configs: any) {
		return this.gateway.post('devices/saveMultiDevicesSettings', { deviceIds, configs });
	}

	getDeviceLastConnectTime(macAddress: string) {
		return this.gateway.get(`devices/device-last-connect-time/${macAddress}`, new CustomHttpParams({ noUIBlock: true }));
	}

	showManageDeviceOption(device: any, site?: any) {
		const deviceSiteId = device.site_id || site.id;

		return this.usersService.hasAccessFunction('super_admin', 'write') ||
			((this.usersService.hasAccessFunction('device_management') || this.usersService.hasAccessFunction('sct_inventory_device_management')) && !site.is_special) ||
			(deviceSiteId === SCT_INVENTORY_SITE_ID && this.usersService.hasAccessFunction('sct_inventory_device_management'));
	}

	getSitePanelIds(siteId: number, macAddress: string | null = null, options?: { getDevices?: boolean, getPanelConsumedEnergy?: boolean, quarter?: number | string, year?: number }) {
		return this.gateway.post('devices/panel-ids', { siteId, macAddress, options }, new CustomHttpParams({ noUIBlock: true }));
	}

	insertPanel(siteId: number, macAddress: string | null, panel_id: string) {
		return this.gateway.post('devices/insert-panel-id', { siteId, macAddress, panel_id }, new CustomHttpParams({ noUIBlock: false }));
	}
}
