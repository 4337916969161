<div class="d-flex mb-3" *ngIf="settings['hasExport'] || settings['hasReset']">
	<div class="dropdown dropstart ms-auto" appDropdown>
		<img class="size-20x20 dropdown opacity-50 pointer me-2" dropdown-toggle src="/sctportal/images/settings.svg">
		<ul class="dropdown-menu grid-dropdown-menu dropdown-menu-custom dropdown-menu-right" role="menu">
			<li class="dropdown-header">{{'g.actions' | translate}}</li>
			<li *ngIf="settings['hasExport']">
				<a class="pointer" (click)="onBtExport()"><small><i class="fa fa-download me-1"></i>{{'g.export_data' | translate}}</small></a>
			</li>
			<li *ngIf="settings['hasReset']">
				<a class="pointer" (click)="onReset()"><small><i class="fa fa-repeat me-1"></i>{{'g.reset' | translate}}</small></a>
			</li>
			<li class="divider" *ngIf="settings['hasSelectColumn']"></li>
			<li class="dropdown-header" *ngIf="settings['hasSelectColumn']">{{'g.columns' | translate}}</li>
			<ng-container *ngIf="settings['hasSelectColumn']">
				<li *ngFor="let col of columns">
					<a class="pointer" (click)="toggleColumn(col)"><small><i class="fa" [ngClass]="col.hide ? 'fa-times' : 'fa-check'"></i> {{col.headerName}}</small></a>
				</li>
			</ng-container>
		</ul>
	</div>
</div>
<ag-grid-angular 
	class="ag-theme-balham no-print"
	[ngStyle]="{'height': (settings.height == +settings.height) ? settings.height+'px' : settings.height}" 
	[rowData]="data" 
	[columnDefs]="columns"
	[rowHeight]="40"
	[suppressCsvExport]="!settings['hasExport']"
	[pagination]="settings['pagination']"
	[paginationPageSize]="settings['paginationPageSize']"
	[defaultColDef]="defaultColDef"
	[domLayout]="settings['domLayout']"
	(gridReady)="onGridReady($event)"
	#agGrid
	id="myGrid"
	[rowSelection]="'multiple'"
	[rowMultiSelectWithClick]="true"
	[columnTypes]="customizedColumnTypes"
	[frameworkComponents]="frameworkComponents"
	[components]="component"
	[suppressColumnVirtualisation]="autoSizeColumns"
	(sortChanged)="onSort()"
	(selectionChanged)="selectionChanged()"
	(filterChanged)="onFilter()"
>
</ag-grid-angular>

<div class="ag-status-panel ag-status-panel-aggregations no-print" *ngIf="gridApi && settings.showSummary">
	<div class="ag-name-value" key="count" default-value="Count" ref="countAggregationComp">
		<span *ngIf="gridApi.getDisplayedRowCount() != totalItemsCount"> {{'g.showing_items' | translate}}: {{gridApi.getDisplayedRowCount()}}</span>
		{{'g.total_items' | translate}}: {{totalItemsCount}} <span *ngIf="gridApi.getSelectedRows().length > 0">({{'g.selected' | translate}}: {{gridApi.getSelectedRows().length}} )</span>
	</div>
</div>