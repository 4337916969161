import { Injectable } from '@angular/core';
import { GatewayService } from '@app/core/services/gateway/gateway.service';

@Injectable()
export class NoteService {

	route = '/note';

	shareOptions = {
		public: 'public',
		private: 'private',
		sct: 'sct'
	};

	reminderFrequencyOptions = {
		none: 'none',
		daily: 'daily',
		weekly: 'weekly'
	};

	noteTypes = {
		user: 'user_note',
		system: 'system_note'
	};

	constructor(
		private httpClient: GatewayService
	) { }

	getModelNotes(modelId: number | string, modelType: string | null, options?: any) {
		return this.httpClient.post(this.route + '/getModelNotes', { modelId, modelType, options });
	}

	updateNOCNote(mac_address: string, note_text: string, site_id: number) {
		return this.httpClient.post(this.route + '/update-noc-note', { mac_address, note_text, site_id});
	}

	getNOCNote(mac_address: string, site_id: number) {
		return this.httpClient.post(this.route + '/get-noc-status-note', { mac_address, site_id});
	}

	addModelNotes(modelId: number | string, modelType: string | null, options: any) {
		return this.httpClient.post(this.route + '/addModelNotes', { modelId, modelType, options });
	}

	deleteModelNotes(noteId: number, modelId: number | string, modelType: string | null, options?: any) {
		return this.httpClient.post(this.route + '/deleteModelNotes', { noteId, modelId, modelType, options });
	}

	updateReminderFreq(noteId: number, modelId: number | string, modelType: string | null, reminderFreq: string, options?: any) {
		return this.httpClient.post(this.route + '/updateReminderFreq', { noteId, modelId, modelType, reminderFreq, options });
	}

	updateShareType(noteId: number, modelId: number | string, modelType: string | null, shareType: string, options?: any) {
		return this.httpClient.post(this.route + '/updateShareType', { noteId, modelId, modelType, shareType, options });
	}

	getSystemNoteUserInfo() {
		return {
			id: 2147483647,
			first_name: 'System',
			last_name: 'User'
		};
	}
}
