import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subscription, Observable, fromEvent, BehaviorSubject } from 'rxjs';
import { SitesService } from '@app/business/sites/sites.service';
import { UsersService } from '@app/business/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { find } from 'lodash';
import _ from 'lodash';
import { CommonDataService } from '@app/shared/services/common-data.service';

@Injectable({
	providedIn: 'root'
})
export class SideMenuService {

	resizeObservable$!: Observable<Event>;
	resizeSubscription$!: Subscription;

	public leftMenuList: MenuItem[] = [];
	user: any;

	private dataSubject = new BehaviorSubject<Object[]>([]);
	data = this.dataSubject.asObservable();

	private currentCustomerSubject = new BehaviorSubject<boolean>(false);
	currentCustomer = this.currentCustomerSubject.asObservable();

	private currentSiteSubject = new BehaviorSubject<boolean>(false);
	currentSite = this.currentSiteSubject.asObservable();

	private expandedCustomersSubject = new BehaviorSubject<Object[]>([]);
	expandedCustomers = this.expandedCustomersSubject.asObservable();

	private hideMenuViewSubject = new BehaviorSubject<boolean>(false);
	hideMenuView = this.hideMenuViewSubject.asObservable();

	private filteredSitesSubject = new BehaviorSubject<Object[]>([]);
	filteredSites = this.filteredSitesSubject.asObservable();

	private sideMenuFiltersSubject = new BehaviorSubject<Object>({});
	sideMenuFilters = this.sideMenuFiltersSubject.asObservable();

	private sitesMapClickedSiteSubject = new BehaviorSubject<Object>({});
	sitesMapClickedSite = this.sitesMapClickedSiteSubject.asObservable();

	private visibilitySubject = new BehaviorSubject<boolean>(false);
	visibility = this.visibilitySubject.asObservable();

	private panelVisibilitySubject = new BehaviorSubject<boolean>(true);
	leftPanelVisibility = this.panelVisibilitySubject.asObservable();

	private siteMapAnalyticsSubject = new BehaviorSubject<object>({});
	siteMapAnalytics = this.siteMapAnalyticsSubject.asObservable();

	showMap = true;

	siteInfoBoxVisibility = false;
	routeHasSiteInfoBox = false;
	visible = true;
	closedWhenResized = false;

	numberOfSites = 0;

	constructor(
		private translateService: TranslateService,
		private usersService: UsersService,
		private commonDataService: CommonDataService,
	) {
		this.resizeObservable$ = fromEvent(window, 'resize');
		this.resizeSubscription$ = this.resizeObservable$.subscribe(() => {
			const sideMenuToggle = this.visibilitySubject.value;

			if (
				sideMenuToggle &&
				window.innerWidth < 991 &&
				!this.closedWhenResized
			) {
				this.show(!sideMenuToggle, true);
				this.closedWhenResized = true;
			} else if (
				!sideMenuToggle &&
				window.innerWidth > 991 &&
				this.closedWhenResized
			) {
				this.closedWhenResized = false;
				this.show(!sideMenuToggle, true);
			}
		});

		this.usersService.currentUser.subscribe((user: any) => {
			this.leftMenuList = [];
			if (user && Object.keys(user).length > 0) {
				this.user = user;
				this.InitLeftMenu();
			}
		});
	}

	private InitLeftMenu() {
		this.leftMenuList = [
			{
				styleClass: 'left-menu-arrow-back',
				icon: 'pi pi-fw pi-arrow-left',
				command: () => {
					const sideMenuToggle = this.visibilitySubject.value;
					this.show(!sideMenuToggle, true);
					this.reDrawMap();
				},
				id: 'side-menu',
			},
			{
				icon: 'pi pi-fw pi-home',
				routerLink: 'sites-map',
			},
			{
				icon: 'pi pi-fw pi-th-large',
				visible: this.userHasAdminLogsTab(),
				items: [
					{
						label: this.translateService.instant('left_menu.admin'),
						visible: this.userHasAdminTab(),
						items: [
							{
								label: this.translateService.instant('g.users'),
								routerLink: '/user/search',
								visible: this.usersService.hasAccessFunction('search_users')
							},
							{
								label: this.translateService.instant('left_menu.customer'),
								routerLink: '/customers',
								visible: this.user.is_admin
							},
							{
								label: this.translateService.instant('g.enterprise'),
								routerLink: '/enterprise',
								visible: this.usersService.hasAccessFunction('enterprise_management')
							},
							{
								label: this.translateService.instant('left_menu.registration_request'),
								routerLink: '/admin-panel/registration-requests',
								visible: this.usersService.hasAccessFunction('registration_requests')
							},
							{
								label: this.translateService.instant('left_menu.time_lost_report'),
								routerLink: '/admin-panel/time-lost-report',
								visible: this.usersService.hasAccessFunction('show_warnings')
							},
							{
								label: this.translateService.instant('left_menu.historical_official_report'),
								routerLink: '/admin-panel/historical-official-report',
								visible: this.usersService.hasAccessFunction('show_warnings')
							},
							{
								label: this.translateService.instant('left_menu.audit'),
								routerLink: '/admin-panel/audit',
								visible: this.user.is_admin
							},
							{
								label: this.translateService.instant('left_menu.admin_actions'),
								visible: this.usersService.hasAccessFunction('sched_functions_control'),
								routerLink: '/admin-panel/admin-actions'
							},
							{
								label: this.translateService.instant('left_menu.upload_fw_file'),
								visible: this.usersService.hasAccessFunction('upload_manual_fw'),
								routerLink: '/admin-panel/upload-fw-file'
							},
							{
								label: this.translateService.instant('left_menu.smart_rebates_sites'),
								routerLink: '/admin-panel/smart-rebates-sites',
								visible: this.usersService.hasAccessFunction('official_reporting') || this.usersService.hasAccessFunction('site_management')
							},
							{
								label: this.translateService.instant('left_menu.iccid_changes_report'),
								routerLink: '/admin-panel/iccid-changes-report',
								visible: true,
							},
							{
								label: this.translateService.instant('Generate Fake Data'),
								routerLink: '/admin-panel/data-generate',
								visible: !this.commonDataService.productionDomain.includes(window.location.hostname) && !this.commonDataService.stagingDomain.includes(window.location.hostname),
							},
						],
					},
					{
						label: this.translateService.instant('left_menu.push_firmware'),
						routerLink: '/management/firmware-update',
						visible: this.usersService.hasAccessFunction('site_management')
					},
					{
						label: this.translateService.instant('left_menu.device_management'),
						visible: this.userHasDeviceTab(),
						items: [
							{
								label: this.translateService.instant('left_menu.cm_device'),
								routerLink: '/admin-panel/cm-device',
								visible: this.usersService.hasAccessFunction('device_management')
							},
							{
								label: this.translateService.instant('device.add_device'),
								routerLink: '/admin-panel/cm-device/1/add',
								visible: this.usersService.hasAccessFunction('add_device')
							},
							{
								label: this.translateService.instant('left_menu.blocked_fw_reports'),
								routerLink: '/management/blocked-fw-reports',
								visible: this.usersService.hasAccessFunction('device_management')
							},
							{
								label: this.translateService.instant('left_menu.manage_device_requests'),
								routerLink: '/admin-panel/device-management/device-management-requests',
								visible: this.usersService.hasAccessFunction('device_management')
							},
							{
								label: this.translateService.instant('left_menu.warnings'),
								routerLink: '/admin-panel/warnings',
								visible: this.usersService.hasAccessFunction('device_management')
							},
							{
								label: this.translateService.instant('left_menu.disconnected_devices'),
								routerLink: '/admin-panel/disconnected_devices',
								visible: this.usersService.hasAccessPermission(null, 'noc')
							},
							{
								label: this.translateService.instant('left_menu.sim_management'),
								routerLink: '/admin-panel/sim_management',
								visible: this.usersService.hasAccessPermission(null, 'noc') || this.usersService.hasAccessFunction('sim_management')
							},
							{
								label: this.translateService.instant('left_menu.sim_info'),
								routerLink: '/admin-panel/sim-info',
								visible: this.usersService.hasAccessPermission(null, 'noc')
							},
						],
					},
					{
						label: this.translateService.instant('left_menu.logs'),
						visible: this.userHasLogsTab(),
						items: [
							{
								label: this.translateService.instant('left_menu.action_logs'),
								routerLink: '/admin-panel/action-logs',
								visible: this.usersService.hasAccessFunction('access_action_logs')
							},
							{
								label: this.translateService.instant('left_menu.error_logs'),
								routerLink: '/admin-panel/error-logs',
								visible: this.usersService.hasAccessFunction('access_error_logs')
							},
							{
								label: this.translateService.instant('left_menu.rejected_device_connection'),
								routerLink: '/admin-panel/rejected-device-connection',
								visible: this.usersService.hasAccessFunction('access_rejection_logs')
							},
							{
								label: this.translateService.instant('contact_us.title'),
								routerLink: '/admin-panel/contact-us',
								visible: this.usersService.hasAccessFunction('admin_contact_us')
							},
							{
								label: this.translateService.instant('left_menu.unused_logs'),
								routerLink: '/admin-panel/unused-logs',
								visible: this.usersService.hasAccessFunction('access_error_logs')
							},
							{
								label: this.translateService.instant('apis_logs.title'),
								routerLink: '/admin-panel/apis-logs',
								visible: this.usersService.hasAccessFunction('apis_logs')
							},
							{
								label: this.translateService.instant('connectivity_verification.title'),
								routerLink: '/admin-panel/connectivity-verification-log',
								visible: this.usersService.hasAccessPermission(null, 'noc')
							},
							{
								label: this.translateService.instant('update_firmware_log.title'),
								routerLink: '/admin-panel/update-firmware-log',
								visible: this.usersService.hasAccessPermission(null, 'noc')
							},
						]
					},
					{
						label: this.translateService.instant('left_menu.permissions_management.title'),
						visible: this.usersService.hasAccessFunction('permissions_management'),
						items: [
							{
								label: this.translateService.instant('left_menu.permissions_management.user'),
								routerLink: '/permissions/user'
							},
							{
								label: this.translateService.instant('left_menu.permissions_management.enterprise'),
								routerLink: '/permissions/enterprise'
							}
						]
					},
					{
						label: this.translateService.instant('left_menu.schedule.title'),
						visible: this.usersService.hasAccessFunction('sched_functions_control'),
						items: [
							{
								label: this.translateService.instant('left_menu.schedule.sched_monitor'),
								routerLink: '/admin-panel/sched-monitor'
							},
							{
								label: this.translateService.instant('left_menu.schedule.sched_control'),
								routerLink: '/admin-panel/sched-control'
							},
							{
								label: this.translateService.instant('left_menu.schedule.run_sched_on_demand'),
								routerLink: '/admin-panel/run-sched',
								visible: !this.commonDataService.productionDomain.includes(window.location.hostname),
							}
						]
					},
					{
						label: this.translateService.instant('left_menu.receiving_shipment'),
						visible: this.usersService.hasAccessFunction('shipment_management'),
						routerLink: '/management/receiving-shipment'
					},
					{
						label: this.translateService.instant('left_menu.shipment_quality'),
						visible: this.usersService.hasAccessFunction('shipment_management'),
						routerLink: 'management/shipment-quality'
					},
					{
						label: this.translateService.instant('left_menu.shipping_out'),
						visible: this.usersService.hasAccessFunction('shipment_management') && this.usersService.hasAccessFunction('super_admin'),
						routerLink: '/management/shipping-out'
					},
					{
						label: this.translateService.instant('multi_tariff.menu_label'),
						visible: this.usersService.hasAccessFunction('global_multi_tariff_profiles'),
						routerLink: 'multi-tariff/profiles/list'
					},
					{
						label: this.translateService.instant('left_menu.sct_managed_shipping_out'),
						visible: this.usersService.hasAccessFunction('shipment_management'),
						routerLink: '/management/sct-managed-shipping-out'
					},
					{
						label: this.translateService.instant('left_menu.inventory-check'),
						visible: this.usersService.hasAccessFunction('shipment_management'),
						routerLink: '/management/inventory-check'
					},
					{
						label: this.translateService.instant('left_menu.rma'),
						visible: this.usersService.hasAccessFunction('shipment_management'),
						routerLink: '/management/rma'
					},
					{
						label: this.translateService.instant('left_menu.rma-processing'),
						visible: this.usersService.hasAccessFunction('shipment_management'),
						routerLink: '/management/rma-processing'
					},
					{
						label: this.translateService.instant('left_menu.manage_orders'),
						visible: this.usersService.hasAccessFunction('shipment_management') || this.usersService.hasAccessFunction('view_orders') || this.usersService.hasAccessFunction('create_orders'),
						routerLink: '/orders/manage'
					},
					{
						label: this.translateService.instant('registration_purposes.registration_reports'),
						visible: this.usersService.hasAccessFunction('registration_reports'),
						routerLink: '/registration-reports'
					}
				],
			},
		];
	}

	private userHasAdminTab() {
		return (
			this.usersService.hasAccessFunction('search_users') ||
			this.user.is_admin ||
			this.usersService.hasAccessFunction('enterprise_management') ||
			this.usersService.hasAccessFunction('registration_requests') ||
			this.usersService.hasAccessFunction('official_reporting') ||
			this.usersService.hasAccessFunction('site_management') ||
			this.usersService.hasAccessFunction('show_warnings')
		);
	}

	private userHasDeviceTab() {
		return (
			this.usersService.hasAccessFunction('add_device') ||
			this.usersService.hasAccessFunction('device_management')
		);
	}

	private userHasLogsTab() {
		return (
			this.usersService.hasAccessFunction('access_error_logs') ||
			this.usersService.hasAccessFunction('access_action_logs') ||
			this.usersService.hasAccessFunction('access_rejection_logs') ||
			this.usersService.hasAccessFunction('admin_contact_us') ||
			this.usersService.hasAccessPermission(null, 'noc')
		);
	}

	private userHasAdminLogsTab() {
		return (
			this.usersService.hasAccessFunction('search_users') ||
			this.usersService.hasAccessFunction('registration_requests') ||
			this.usersService.hasAccessFunction('admin_contact_us') ||
			this.usersService.hasAccessFunction('access_error_logs') ||
			this.usersService.hasAccessFunction('access_action_logs') ||
			this.usersService.hasAccessFunction('access_rejection_logs') ||
			this.usersService.hasAccessFunction('sched_functions_control') ||
			this.usersService.hasAccessFunction('add_device') ||
			this.usersService.hasAccessFunction('dev_team') ||
			this.usersService.hasAccessFunction('site_management') ||
			this.usersService.hasAccessFunction('device_management') ||
			this.usersService.hasAccessFunction('show_warnings') ||
			this.usersService.hasAccessFunction('registration_reports') ||
			this.user.is_admin
		);
	}

	ngOnDestroy(): void {
		this.resizeSubscription$.unsubscribe();
	}

	setData(data: any) {
		if (data) {
			let sites: any[] = [];
			_.map(data, "sites").forEach(item => {
				sites = sites.concat(item);
			});
			this.numberOfSites = sites.length;

		}
		this.dataSubject.next(data);
	}

	setExpandedCustomers(customersArr: any) {
		this.expandedCustomersSubject.next(customersArr);
	}

	setFilteredSites(sites: any) {
		this.filteredSitesSubject.next(sites);
	}

	setSideMenuFilters(filters: any) {
		this.sideMenuFiltersSubject.next(filters);
	}

	setSiteMapAnalytics(data: any) {
		this.siteMapAnalyticsSubject.next(data);
	}

	addExpandedCustomer(customer: any) {
		let expandedCustomersArr = this.expandedCustomersSubject.value;
		let exists = find(expandedCustomersArr, (item: any) => {
			return item.id == customer.id;
		});
		if (!exists) {
			expandedCustomersArr.push(customer);
			this.expandedCustomersSubject.next(expandedCustomersArr);
		}
	}

	getExpandedCustomers() {
		return this.expandedCustomersSubject.value;
	}

	removeExpandedCustomer(customer: any) {
		let expandedCustomersArr = this.expandedCustomersSubject.value;
		expandedCustomersArr = expandedCustomersArr.filter((obj: any) => obj.id != customer.id);
		this.setExpandedCustomers(expandedCustomersArr);
	}

	getSiteInfoBoxVisibility() {
		return this.siteInfoBoxVisibility;
	}

	getRouteHasSiteInfoBox() {
		return this.routeHasSiteInfoBox;
	}

	setSitesMapClickedSite(site: any) {
		this.sitesMapClickedSiteSubject.next(site);
	}

	setCurrentSite(site: any) {
		this.currentSiteSubject.next(site);
	}

	setCurrentCustomer(customer: any) {
		this.dataSubject.value?.forEach((c: any) => { if (c.id == customer.id) { c.expanded = true } });
		this.currentCustomerSubject.next(customer);
	}

	setRouteHasSiteInfoBox(value: boolean) {
		this.routeHasSiteInfoBox = value;
	}

	show(show = true, byToggle = false) {
		if (!byToggle && this.leftMenuList[0]) {
			this.leftMenuList[0].visible = show;
		}
		this.visibilitySubject.next(show);
	}

	getSideMenuFilters() {
		return this.sideMenuFiltersSubject.value;
	}

	setSiteInfoBoxVisibility(value: boolean) {
		this.siteInfoBoxVisibility = value;
	}

	setHideMenuView(value: boolean) {
		this.hideMenuViewSubject.next(value);
	}

	getHideMenuView() {
		return this.hideMenuViewSubject.value;
	}

	reDrawMap() {
		this.showMap = false;
		setTimeout(() => {
			this.showMap = true;
		}, 500);
	}
}
