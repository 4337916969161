import { GatewayService } from '@app/core/services/gateway/gateway.service';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NEW_DEVICES_SITE, SCT_INVENTORY_SITE_ID } from '@app/core/model/constants';

@Injectable({
	providedIn: 'root'
})
export class SitesService {

	route = 'site/';

	defaultMapLocation = {
		latitude: 28.623421,
		longitude: -81.212219
	};
	restrictedSiteIds = [NEW_DEVICES_SITE, SCT_INVENTORY_SITE_ID];

	constructor(
		private gateway: GatewayService
	) { }

	getSite(siteId: number, options: any) {
		let params = new HttpParams();
		for (const search in options) {
			params = params.append(search, options[search]);
		}

		return this.gateway.get(this.route + siteId, params);
	}

	saveSite(site: any) {
		return this.gateway.post(this.route + 'saveSite', { site });
	}

	getUserSitesInfo(options?: any) {
		return this.gateway.get(this.route + 'getUserSitesInfo', options);
	}

	getUserSitesInfoWithAnalytics(options?: any) {
		return this.gateway.get(this.route + 'getUserSitesInfoWithAnalytics', options);
	}

	getSmartRebatesSitesInfo(options?: any) {
		return this.gateway.get(this.route + 'getSmartRebatesSitesInfo', options);
	}

	downloadConsumptionsReport(year: number, quarter: number, siteId: number, selectedStartDate: number) {
		return this.gateway.post('reports/get-sr-consumption-reports', { year, quarter, siteId, selectedStartDate });
	}

	getSitesFirmwareData() {
		return this.gateway.post(this.route + 'getSitesFirmwareData', {});
	}

	getSiteFwUpdateRequests(type: string, siteId: number) {
		return this.gateway.post(this.route + 'getSiteFwUpdateRequests', { type, siteId });
	}

	pushFirmwareUpdate(siteId: number, type: string, firmwareVersion: number, firmwareVersionsType: number) {
		return this.gateway.post(this.route + 'pushFirmwareUpdate', { siteId, type, firmwareVersion, firmwareVersionsType });
	}

	abortFirmwareUpdate(siteId: number, firmwareVersionsType: number) {
		return this.gateway.post(this.route + 'abortFirmwareUpdate', { siteId, firmwareVersionsType });
	}

	deleteSite(siteId: number) {
		return this.gateway.delete(this.route + siteId);
	}

	getSiteDevicesWarranty(siteId: number) {
		return this.gateway.get(this.route + 'devices-warranty/' + siteId);
	}

	getSiteDownloadStatus(siteId: number) {
		return this.gateway.post(this.route + 'getSiteDownloadStatus', { siteId });
	}

	getSitesAnalyticsData() {
		return this.gateway.post(this.route + 'getSitesAnalyticsData', {});
	}

	getEnterpriseSitesInfo(enterpriseId: any) {
		return this.gateway.post(this.route + 'getEnterpriseSitesInfo', { enterpriseId });
	}

	getSiteReportsData(siteId: number) {
		return this.gateway.post('site-analytics/getReportsData', { siteId });
	}

	getPeakPowerBySiteId(siteId: number, fromTime: number, toTime: number) {
		return this.gateway.post('site-analytics/getPeakPower', { siteId, fromTime, toTime });
	}

	getUsageData(siteId: number, fromTime: number, toTime: number) {
		return this.gateway.post('site-analytics/getUsageData', { siteId, fromTime, toTime });
	}

	getEnergyUsage(siteId: number, fromTime: number, toTime: number) {
		return this.gateway.post('site-analytics/getEnergyUsage', { siteId, fromTime, toTime });
	}

	getPerformanceSummary(siteId: number, fromTime: number, toTime: number) {
		return this.gateway.post('site-analytics/getPerformanceSummary', { siteId, fromTime, toTime });
	}

	checkPageAccess(customerId: number, siteId: number, macAddress?: string) {
		return this.gateway.post('devices/check-page-access', { customerId, siteId, macAddress });
	}

	doMeterReading(siteId: number, year: number, quarter: string, isOfficial: boolean, forceInComplete: boolean) {
		return this.gateway.post('reports/do-meter-reading', { siteId, year, quarter, isOfficial, forceInComplete });
	}

	burnOfficialReports(siteId: number) {
		return this.gateway.post('reports/burn-site-official-reports', { siteId });
	}

	regenSiteData(siteId: number) {
		return this.gateway.post('device-analytics/genPerformanceAnalytics', { site_id: siteId });
	}

	getSiteOfficialReportInfo(siteId: number) {
		return this.gateway.get('devices/get-official-report-info-for-site/' + siteId);
	}

	downloadReport(reportId: number, siteId: number, type: string) {
		return this.gateway.post('reports/download-report-file', { reportId, siteId, type });
	}

	getInvoicesReports(siteId: number) {
		return this.gateway.get('reports/get-invoices-report-for-site/' + siteId);
	}

	issueInvoice(siteId: number, year: number, quarter: string) {
		return this.gateway.post('reports/issue-invoice', { siteId, year, quarter });
	}

	registrationPurposes(siteId: number) {
		return this.gateway.post('reports/registration-purposes', { siteId });
	}

	getSitesWithCustomerInfo() {
		return this.gateway.post('site/getSitesWithCustomerInfo', {});
	}

}
