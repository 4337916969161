import { Directive, HostListener, HostBinding, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
	selector: '[appDropdown]',

})
export class DropdownDirective {
	constructor(
		private elementRef: ElementRef,
		private renderer2: Renderer2) { }
	@HostBinding('class.open') isOpen = false;
	outsideClickListener!: Function;

	@HostListener('click', ["$event"]) toggleOpen($event: any) {
		this.isOpen = !this.isOpen;
		if (this.isOpen) {
			this.outsideClickListener = this.renderer2.listen('document', 'mousedown', ($event) => {
				this.handleEventMouseDown($event);
			});
		} else {
			this.outsideClickListener();
		}

		if (this.isOpen) {
			this.renderer2.setStyle(this.elementRef.nativeElement.querySelector('ul'), 'display', 'block');
		} else {
			this.renderer2.setStyle(this.elementRef.nativeElement.querySelector('ul'), 'display', 'none');
		}

		var hasActionClass = $event.target.classList.contains('has-action');
		if ($event.target['tagName'].toLowerCase() == "a" || hasActionClass) {
			if (!$event.target['href'] && !hasActionClass) {
				$event.stopPropagation();
			}
		} else {
			$event.stopPropagation();
		}
	}
	handleEventMouseDown($event: any) {
		if (!this.isOpen && this.outsideClickListener) {
			this.outsideClickListener();
		}
		let eventTriggeredInsideHost = this.eventTriggeredInsideHost($event);
		if (this.isOpen && !eventTriggeredInsideHost) {
			this.isOpen = false;
			this.renderer2.setStyle(this.elementRef.nativeElement.querySelector('ul'), 'display', 'none');
		}
	}
	eventTriggeredInsideHost(event: any) {
		var current = event.target;
		var host = this.elementRef.nativeElement;

		do {
			if (current === host) {
				return (true);
			}
			current = current.parentNode;
		} while (current);
		return (false);
	}
	ngOnDestroy() {
		if (this.outsideClickListener) {
			this.outsideClickListener();
		}
	}
}