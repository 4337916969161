import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UsersService } from '@app/business/users/users.service';
import { DevicesService } from '../../business/sites/devices.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '@app/shared/services/common.service';
import { SCTDateRange, SCTDateRangeConfig } from '@app/shared/date-range/date-range-modal';
import moment from 'moment';
import { DateRangeComponent } from '@app/shared/date-range/date-range.component';
import { SCTDialog } from '@app/shared/sct-dialog/sct-dialog.model';
import { PermissionType } from '@app/core/model/constants';
import { FLOAT_VALUE_LIMIT } from '@app/core/model/constants';
import { cloneDeep } from 'lodash';

interface keyValue {
	key: string,
	value: any
}

@Component({
	selector: 'app-warnings-history-table',
	templateUrl: './warnings-history-table.component.html',
	styleUrls: ['./warnings-history-table.component.css']
})
export class warningsHistoryTableComponent implements OnInit {
	@ViewChild('dateRangePicker') dateRangePicker!: DateRangeComponent;
	@Input() canIgnorePermission?: boolean = false;
	@Input() siteId?: number;

	triggeredQVValuesDialog!: SCTDialog;
	filterOptions: {from: Date | null, to: Date | null} = {from: null, to: null};
	dataTable: any[] = [];
	columnDefs: any[] = [];
	allColumnsKeys: string[] = [];
	dateRange: SCTDateRange = { start: moment().subtract(6, 'day').toDate(), end: new Date() };
	dateRangeConfig: SCTDateRangeConfig = {};
	currentUser: any = {};
	enterprisePermissions: any = {};
	siteName = '';
	customerName = '';

	dateFields = ['timestamp'];
	dateTimeFields = ['trigger_time', 'clear_time'];
	clickableFields = ['triggered_qv_values', 'cleared_qv_values', 'acknowledge'];
	hiddenFields = ['id'];

	triggeredQVValues: any = {
		customer_name: '',
		site_name: '',
		serial_number: '',
		warning_text: ''
	};
	noQVData = false;

	qvFields: {
		[key: string]: string[];
	} = {
		main: ['ap_ip', 'ap_status', 'hw_status', 'wifi_status', 'rssi', 'cellular_connected', 'wifi_ip', 'setup_network_on'],
		live_event: ['sequence_id', 'timestamp', 'max_voltage', 'min_voltage', 'max_current', 'consumed_energy_duration', 'negative_peak_power', 'event_consumed_energy'],
		live_rt: ['sequence_id', 'voltage_a', 'voltage_b', 'voltage_c', 'current_a', 'current_b', 'current_c', 'pf_a', 'pf_b', 'pf_c','line_frequency_com', 'voltage_a_ll', 'voltage_b_ll', 'voltage_c_ll'],
		glitches: ['sequence_id', 'power_event_value']
	}
	floutFields = ['max_voltage', 'min_voltage', 'max_current','voltage_a', 'voltage_b', 'voltage_c', 'current_a', 'current_b', 'current_c', 'pf_a', 'pf_b', 'pf_c','voltage_a_ll', 'voltage_b_ll', 'voltage_c_ll'];
	staStatus = ['wifi_status', 'eth_status'];
	statusMapValue: any = {
		wifi_status: 'wifi_ip',
		eth_status: 'eth_ip'
	};

	warningStatus = {
		open: 1,
		close: 2,
		acknowledged: 3
	}

	allColumns: { key: string }[] = [
		{
			key: 'id',
		},
		{
			key: 'serial_number'
		},
		{
			key: 'customer_name'
		},
		{
			key: 'panel_id'
		},
		{
			key: 'site_name'
		},
		{
			key: 'warning_text'
		},
		{
			key: 'trigger_time'
		},
		{
			key: 'clear_time'
		},
		{
			key: 'cleared_qv_values'
		},
		{
			key: 'acknowledge'
		},
	];

	tableFilter: 'current_warnings'  | 'period' = 'current_warnings';
	tableFilterList = [
		{id: 'current_warnings', name: this.translateService.instant('warnings.current_warnings')},
		{id: 'period', name: this.translateService.instant('warnings.period')}
	];

	excludePermissionWarnings = ['lost_rtc', 'password', 'disconnected_device', 'invalid_device_zone', 'no_setup'];

	constructor(
		private usersService: UsersService,
		private deviceService: DevicesService,
		private translateService: TranslateService,
		private commonService: CommonService
	) {
		this.currentUser = this.usersService.getCurrentUser();
		this.enterprisePermissions = this.currentUser.permissions;
	}

	ngOnInit() {
		this.getWarnings();
		this.buildGrid();
		this.manageDialog();
	}

	manageDialog() {
		this.triggeredQVValuesDialog = {
			header: this.translateService.instant('warnings.triggered_qv_values'),
			hideFooter: true,
			width: '1000px'
		};
	}

	onDatesChanged(){
		this.getWarnings();
	}

	getWarnings() {
		let dateRange: any = [null, null];
		if(this.tableFilter != 'current_warnings')
			dateRange = this.dateRangePicker.getDate();

		let from = null;
		if(dateRange[0]) {
			from = moment(this.commonService.getDateInLocalTime(new Date(dateRange[0] * 1000))).utc().startOf('day').unix();
		}
		let to = null;
		if(dateRange[1]) {
			to = moment(this.commonService.getDateInLocalTime(new Date(dateRange[1] * 1000))).utc().endOf('day').unix();
		}
		const siteId = this.siteId ? this.siteId : null;

		this.deviceService.getWarnings(from, to, siteId).subscribe((res) => {
			const tableData: any[] = [];
			this.siteName = res.siteName;
			this.customerName = res.customerName;
			res.warnings.forEach((row: any) => {
				if (!this.userHasPermission(row.warning))
					return

				const rowObj = {
					id: row.id,
					serial_number: row.serial_number,
					customer_name: row.customer_name,
					panel_id: row.panel_id,
					site_name: row.site_name,
					warning_text: this.translateService.instant('warnings.' + row.warning),
					trigger_time:  moment.unix(+(new Date(+row.trigger_time))).format('MM/DD/YYYY HH:mm:ss'),
					clear_time: row.clear_time ? moment.unix(+(new Date(+row.clear_time))).format('MM/DD/YYYY HH:mm:ss'): null ,
					cleared_qv_values: {
						onClick: () => this.prepareQVValues(row),
						type: 'icon',
						icon: 'info',
					},
					acknowledge: {
						onClick: () => this.acknowledgeAction(row),
						type: 'icon',
						icon: row.status == this.warningStatus.close ? 'eye-slash': null,
					},
				}
				tableData.push(rowObj);
			})
			this.dataTable = tableData;
		})
	}

	buildGrid() {
		const gridColumns = [];
		this.allColumnsKeys = this.allColumns.map(item => item.key);

		if (this.siteId) { // remove customer name and site name columns (if we are in site warning page)
			this.allColumnsKeys.splice(this.allColumnsKeys.indexOf('customer_name'), 1);
			this.allColumnsKeys.splice(this.allColumnsKeys.indexOf('site_name'), 1);
		} else { // remove panel id (if we are not in site warning page)
			this.allColumnsKeys.splice(this.allColumnsKeys.indexOf('panel_id'), 1);
		}

		for (let field of this.allColumnsKeys) {
			let colDef: any = {};

			if (this.dateTimeFields.includes(field))
				colDef = { type: 'dateTime', floatingFilterComponentParams: { suppressFilterButton: true } };

			if (this.clickableFields.includes(field))
				colDef = {type: 'clickable'}

			let headerName = field;
			Object.assign(colDef, { headerName: this.translateService.instant('warnings.' + headerName), field: field, colId: field });

			if (!this.hiddenFields.includes(field))
				gridColumns.push(colDef);
		}
		this.columnDefs = gridColumns;
	}

	prepareQVValues(row: any) {
		this.triggeredQVValuesDialog.visible = true;
		this.triggeredQVValuesDialog.header = this.translateService.instant('warnings.triggered_qv_values');
		const data = cloneDeep(row);
		this.setDialogData(data);
	}

	setDialogData(data: any) {
		this.triggeredQVValues['serial_number'] = data.serial_number;
		this.triggeredQVValues['customer_name'] = data.customer_name || this.customerName;
		this.triggeredQVValues['site_name'] = data.site_name || this.siteName;
		this.triggeredQVValues['warning_text'] = this.translateService.instant('warnings.' + data.warning);

		if ( !data.triggered_qv_values && !data.cleared_qv_values ) {
			this.noQVData = true;
			return
		}

		const dataObjectArray = [
			{key: 'main', trigger_object: data.triggered_qv_values, clear_object: data.cleared_qv_values},
			{key: 'live_event', trigger_object: data.triggered_qv_values?.live_event, clear_object: data.cleared_qv_values?.live_event},
			{key: 'live_rt', trigger_object: data.triggered_qv_values.live_rt, clear_object: data.cleared_qv_values?.live_rt},
			{key: 'glitches', trigger_object: data.triggered_qv_values.glitches, clear_object: data.cleared_qv_values?.glitches},
		];

		dataObjectArray.forEach(object => {
			this.triggeredQVValues[object.key] = [];
			this.qvFields[object.key].forEach(field => {
				const fieldData = {
					field_name: field,
					trigger_value: this.formatData(object.trigger_object, field),
					clear_value: this.formatData(object.clear_object, field)
				};
				this.triggeredQVValues[object.key].push(fieldData);
			})
		})
	}

	formatData(object: any, field: string) {
		let returnValue = null;
		if (!(object && field in object))
			return returnValue;

		returnValue = object[field];

		if (['ap_status'].includes(field))
			returnValue = this.translateService.instant('ap_status.'+ object[field]);

		else if (this.staStatus.includes(field))
			returnValue = this.getApStatus(object[field], object[field][this.statusMapValue[field]]);

		else if (['hw_status'].includes(field))
			returnValue = this.getHwStatus(object[field]);

		else if (['setup_network_on'].includes(field))
			returnValue = !!object[field];

		if (this.dateFields.includes(field))
			returnValue = this.commonService.getDateFormattedFromUnixTimeStamp(object[field]);

		if (this.floutFields.includes(field))
			returnValue = object[field].toFixed(2);

		if (['consumed_energy_duration'].includes(field))
			returnValue = this.commonService.timeFormat((object[field] || 0), { limitToOneDay: true, format: 'HH:mm:ss' })

		if (['min_voltage'].includes(field))
			returnValue = object[field] > FLOAT_VALUE_LIMIT ? 0 : Math.abs(object[field] || 0);

		return returnValue;
	}

	acknowledgeAction(row: any) {
		this.deviceService.acknowledgeWarnings(row.warning, row.mac_address).subscribe((data: any) => {
			if (data) {
				if(this.tableFilter == 'current_warnings') {
					this.dataTable = this.dataTable.filter((data) => data.id !== row.id);
					this.buildGrid();
				} else {
					this.getWarnings();
				}
			}
		})
	}

	onFilterChange() {
		if(this.tableFilter == 'current_warnings')
			this.filterOptions = {from: null, to: null};

		this.getWarnings();
	}

	userHasPermission(warning: string) {

		if (this.excludePermissionWarnings.includes(warning) && this.canIgnorePermission)
			return true;

		const includePassword = this.usersService.hasAccessPermission(this.enterprisePermissions, 'device.device_password', PermissionType.WRITE);
		if (warning == 'password' && includePassword)
			return true;

		if (this.usersService.hasAccessPermission({ permission: this.enterprisePermissions.permission }, 'warning.' + warning))
			return true;

		return false;
	}

	getApStatus(status: number, ip: string) {
		let AP_STATUS_STARTED = 1,
			AP_STATUS_STOPPED = 3,
			AP_STATUS_STA_CONNECTED = 4,
			AP_STATUS_STA_DISCONNECTED = 5,
			AP_STATUS_PROBEREQRECVED = 6,
			AP_STATUS_STA_GOT_IP6 = 7,
			AP_STATUS_MAX = 8;

		if (![AP_STATUS_STOPPED, AP_STATUS_MAX].includes(status) && ip != "0.0.0.0" && ip)
			return this.translateService.instant('quick_view.ap_on') + ' ' + ip;

		return this.translateService.instant('quick_view.ap_on');
	}

	getHwStatus(value: number) {
		let hw_status: any = {
			0x01: 'warnings.modbus',
			0x02: 'warnings.rtc',
			0x04: 'warnings.eth',
			0x08: 'warnings.flash_size',
			0x10: 'warnings.port_expander',
		};

		const valueArr = []

		for (const v in hw_status) {
			if ((value & <any>v) != 0) {
				valueArr.push(this.translateService.instant(hw_status[v]));
			}
		}

		return valueArr.length > 0 ? valueArr.join(',') : this.translateService.instant('g.no_error');
	}
}
