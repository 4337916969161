import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { DevicesService } from '@app/business/sites/devices.service';
import { TranslateService } from '@ngx-translate/core';
import { SCTDialog } from '../sct-dialog/sct-dialog.model';
import moment from 'moment';
import { getBeforeXPeriodDate, getMinDateBasedOnInstallationDate } from '../services/utilities';
import { sum } from 'lodash';
import { DatePipe } from '@angular/common';
import { UsersService } from '@app/business/users/users.service';
import { SettingsService } from '@app/business/sites/analytics-settings/settings/service/settings.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-performance-analytics',
	templateUrl: './performance-analytics.component.html',
	styleUrls: ['./performance-analytics.component.css']
})
export class PerformanceAnalyticsComponent implements OnInit {

	@Input() data: any = {};
	@Input() devices!: any[];
	@Input() siteInfo: any = {};

	optionsObject = {
		legend: {
			display: false,
		},
		scales: {
			xAxes: [{
				gridLines: {
					display: false,
					barPercentage: 0.5,
					categoryPercentage: 0.5
				},
			}],
			yAxes: [{
				ticks: {
					beginAtZero: true
				}
			}]
		}
	}

	usageTimeOptionsObject = {
		scales: {
			xAxes: [{
				gridLines: {
					display: false,
					barPercentage: 0.5,
					categoryPercentage: 0.5
				},
			}],
			yAxes: [{
				ticks: {
					beginAtZero: true
				}
			}]
		}
	}

	macAddress!: string;
	logicType!: number;
	usageData: any
	quarterlyUsageDataChart: any;
	monthlyUsageDataChart: any;
	quarterlyUsageDataExport: any = [];
	monthlyUsageDataExport: any = [];
	usageDataChart: any;
	usageDataExport: any = [];
	usageDataChartFilters = {
		start: getBeforeXPeriodDate(1, 'month'),
		end: new Date(new Date().setHours(23, 59, 59, 999))
	};
	peakPowerChart: any;
	peakPowerChartData: any;
	peakPowerExport: any = [];
	peakPowerChartFilters = {
		start: new Date(new Date(new Date().setMonth(new Date().getMonth() - 3)).setHours(0, 0, 0, 0)),
		end: new Date(new Date().setHours(23, 59, 59, 999))
	};
	energyUsageChart: any;
	energyUsageExport: any = [];
	energyUsageChartFilters = {
		start: new Date(new Date(new Date().setMonth(new Date().getMonth() - 3)).setHours(0, 0, 0, 0)),
		end: new Date(new Date().setHours(23, 59, 59, 999))
	};
	performanceSummaryChart: any;
	performanceSummaryExport: any = [];
	performanceSummaryChartData: any[] = [
		// 0: dip
		// 1: swell
		// 2: overcurrent
	];
	performanceSummaryChartFilters = {
		start: new Date(new Date(new Date().setMonth(new Date().getMonth() - 3)).setHours(0, 0, 0, 0)),
		end: new Date(new Date().setHours(23, 59, 59, 999))
	};
	maxDate = new Date();

	// Add Filter
	selectedYear: any = moment().year();

	eventDialog: SCTDialog = {
		header: this.translateService.instant('g.events'),
		dismissableMask: true,
		width: '25vw'
	};
	glitchDialog: SCTDialog = {
		header: this.translateService.instant('g.glitches'),
		dismissableMask: true,
		width: '25vw'
	};
	chartDataExists = {
		quarters: false
	};
	selectedYearUsageReport = moment().year();
	yearsOption: any = [];
	usageDataIsEmpty: boolean = true;
	minDateSelections!: Date;
	energyUsageIsNotEmpty: boolean = true;
	sitePerminssion: Subscription = new Subscription();

	constructor(
		private translateService: TranslateService,
		private commonUtil: CommonService,
		private devicesService: DevicesService,
		private datePipe: DatePipe,
		private usersService: UsersService,
		private settingsService: SettingsService
	) { }

	ngOnInit(): void {
		this.macAddress = this.devices[0].mac_address;
		this.logicType = this.devices[0].event_logic || 0;
		if (this.data && this.data[0] && this.data[0].quarters_data) {
			const installationDate = this.data[0].installation_date * 1000;
			this.minDateSelections = moment(installationDate).toDate();
			const startYear = moment(installationDate).year();
			const currentYear = moment().utc().year();
			for (let year = startYear; year <= currentYear; year++) {
				this.yearsOption.push(year);
			}
			this.selectedYearUsageReport = startYear;
			this.performanceSummaryChartFilters.start = getMinDateBasedOnInstallationDate(3, installationDate);
			this.energyUsageChartFilters.start = getMinDateBasedOnInstallationDate(3, installationDate);
			this.usageDataChartFilters.start = getMinDateBasedOnInstallationDate(3, installationDate);
			this.peakPowerChartFilters.start = getMinDateBasedOnInstallationDate(1, installationDate);
			this.rebuildCharts();
		}
	}

	rebuildCharts() {
		this.formatData();
		this.buildGrid();
	}

	private formatData() {
		const allQData = this.data[0].quarters_data;
		this.chartDataExists.quarters = false;
		for (let period in allQData) {
			this.data[0].quarters_data[period] = this.commonUtil.decompress(allQData[period], 'devicePerformance');
			this.chartDataExists.quarters = true;
		}
		if(this.data[0].monthlyData[this.selectedYear])
			this.chartDataExists.quarters = true;
	}

	private buildGrid() {
		this.updateQuarterlyUsageData();
		this.updateMonthlyUsageData()
		this.updateUsageData([moment.utc(this.usageDataChartFilters.start).unix(), moment.utc(this.usageDataChartFilters.end).unix()]);
		this.updatePeakPower([moment.utc(this.peakPowerChartFilters.start).unix(), moment.utc(this.peakPowerChartFilters.end).unix()]);
		this.updateEnergyUsage([moment.utc(this.energyUsageChartFilters.start).unix(), moment.utc(this.energyUsageChartFilters.end).unix()]);
		this.updatePerformanceSummary([moment.utc(this.performanceSummaryChartFilters.start).unix(), moment.utc(this.performanceSummaryChartFilters.end).unix()]);
	}

	updateQuarterlyUsageData() {
		const billedKwhrs = [];
		const billedAvg = [];
		const consumedUsageTime = [];
		const generatedUsageTime = [];
		const peakPower = [];

		this.quarterlyUsageDataChart = null;
		this.quarterlyUsageDataExport = [];

		for (let index = 1; index <= 4; index++) {
			const quarter = `${this.selectedYearUsageReport}_Q${index}`;
			const data = (this.data[0]?.quarters_data || {})[quarter];
			const numberOfDaysOnQuarter = this.commonUtil.getDaysInQuarter(this.selectedYearUsageReport, index);

			const billedVal = (data?.billed_kwhrs || 0).toFixed(2);
			const consumedUsageTimeVal = (data?.total_consumed_usage_time / 60 || 0).toFixed(2);
			const generatedUsageTimeVal = (data?.total_generated_usage_time / 60 || 0).toFixed(2);
			const peakPowerVal = (data?.negative_peak_power || 0).toFixed(2);
			const billedValAvg = (numberOfDaysOnQuarter ? billedVal / numberOfDaysOnQuarter : 0).toFixed(2);

			billedKwhrs.push(billedVal);
			billedAvg.push(billedValAvg);
			consumedUsageTime.push(consumedUsageTimeVal);
			generatedUsageTime.push(generatedUsageTimeVal);
			peakPower.push(peakPowerVal);
			this.quarterlyUsageDataExport.push([quarter.toUpperCase(), billedVal, billedValAvg, consumedUsageTimeVal, generatedUsageTimeVal, peakPowerVal]);
		}

		if (!billedKwhrs.length)
			return;
		const typeAndColor = { type: 'bar', backgroundColor: '#36c' }
		const emptyLine = { ...typeAndColor, data: 0 }

		this.quarterlyUsageDataChart = {
			billed_kwhrs: {
				labels: ['Q1', 'Q2', 'Q3', 'Q4'],
				datasets: [
					emptyLine, { ...typeAndColor, data: billedKwhrs }, emptyLine,
				]
			},
			daily_billed_kwhrs: {
				labels: ['Q1', 'Q2', 'Q3', 'Q4'],
				datasets: [
					emptyLine, { ...typeAndColor, data: billedAvg }, emptyLine,
				]
			},
			total_usage_time: {
				labels: ['Q1', 'Q2', 'Q3', 'Q4'],
				datasets: [
					{ ...typeAndColor, label: this.translateService.instant('device.total_consumed_usage_time'), data: consumedUsageTime },
					{ ...typeAndColor, label: this.translateService.instant('device.total_generated_usage_time'), backgroundColor: '#FFCA28', data: generatedUsageTime }
				]
			},
			peak_power: {
				labels: ['Q1', 'Q2', 'Q3', 'Q4'],
				datasets: [
					emptyLine, { ...typeAndColor, data: peakPower }, emptyLine,
				]
			}
		}
	}

	getDefaultObject() {
		return {
			billed_kwhrs: 0,
			total_consumed_usage_time: 0,
			peak_power: 0,
			start_date: 0,
			end_date: 0,
			total_generated_usage_time: 0,
			consumed_energy: 0,
			generated_energy: 0,
		};
	}

	updateMonthlyUsageData() {
		const billedKwhrs = [];
		const billedAvg = [];
		const consumedUsageTime = [];
		const generatedUsageTime = [];
		const peakPower = [];
		this.monthlyUsageDataChart = null;
		const selectedData = this.data[0]?.monthlyData[this.selectedYearUsageReport];
		if(!selectedData) return;
		for(let index = 1; index <= 12; index++) {
			const month = moment().month(index - 1).format('MMM');

			const data = (selectedData[index] || this.getDefaultObject());
			const numberOfDaysOnMonth = this.commonUtil.getDaysInMonth(this.selectedYearUsageReport, index);

			const billedVal = (data?.billed_kwhrs || 0).toFixed(2);
			const consumedUsageTimeVal = (data?.total_consumed_usage_time / 60 || 0).toFixed(2);
			const generatedUsageTimeVal = (data?.total_generated_usage_time / 60 || 0).toFixed(2);
			const peakPowerVal = (data?.negative_peak_power || 0).toFixed(2);
			const billedValAvg = (numberOfDaysOnMonth ? billedVal / numberOfDaysOnMonth : 0).toFixed(2);

			billedKwhrs.push(billedVal);
			billedAvg.push(billedValAvg);
			consumedUsageTime.push(consumedUsageTimeVal);
			generatedUsageTime.push(generatedUsageTimeVal);
			peakPower.push(peakPowerVal);
			this.monthlyUsageDataExport.push([`${this.selectedYearUsageReport}_${month}`, billedVal, billedValAvg, consumedUsageTimeVal, generatedUsageTimeVal, peakPowerVal]);
		}

		const typeAndColor = { type: 'bar', backgroundColor: '#36c' };
		// const emptyLine = { ...typeAndColor, data: 0 };
		const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		this.monthlyUsageDataChart = {
			billed_kwhrs: {
				labels: months,
				datasets: [
					{ ...typeAndColor, data: billedKwhrs }
				]
			},
			daily_billed_kwhrs: {
				labels: months,
				datasets: [
					{ ...typeAndColor, data: billedAvg },
				]
			},
			total_usage_time: {
				labels: months,
				datasets: [
					{ ...typeAndColor, label: this.translateService.instant('device.total_consumed_usage_time'), data: consumedUsageTime },
					{ ...typeAndColor, label: this.translateService.instant('device.total_generated_usage_time'), backgroundColor: '#FFCA28', data: generatedUsageTime }
				]
			},
			peak_power: {
				labels: months,
				datasets: [
					{ ...typeAndColor, data: peakPower },
				]
			}
		};
		console.log(this.monthlyUsageDataChart, 'monthlyUsageDataChart');
	}

	updateUsageData(dates: any) {
		if (!Array.isArray(dates))
			dates = dates.dateRange;
		this.devicesService.getDailyDetails(this.macAddress, { fromTime: dates[0], toTime: dates[1] }).subscribe((data: any) => {
			this.usageDataExport = [];
			let billedVal = 0,
				billedAvg = 0,
				consumedUsageTimeVal = 0,
				generatedUsageTimeVal = 0,
				peakPowerVal = 0;
			for (const day in data) {
				const dayData = data[day] || {};
				if (!Object.keys(dayData).length)
					continue;
				this.usageDataExport.push([
					moment.utc(+day * 1000).format('l'),
					dayData.event_consumed_energy,
					dayData.consumed_energy_duration,
					dayData.generated_energy_duration,
					dayData.negative_peak_power
				]);
				billedVal += dayData.event_consumed_energy || 0;
				consumedUsageTimeVal += dayData.consumed_energy_duration;
				generatedUsageTimeVal += dayData.generated_energy_duration;
				peakPowerVal = Math.max(dayData.negative_peak_power, peakPowerVal);
			}
			if (Object.keys(data).length)
				billedAvg = billedVal / Object.keys(data).length;

			this.usageDataChart = [
				{ label: this.translateService.instant('perf_analytic.total_billed_energy'), unit: 'kWh' },
				{ label: this.translateService.instant('perf_analytic.consumed_total_usage_time'), unit: '' },
				{ label: this.translateService.instant('perf_analytic.generated_total_usage_time'), unit: '' },
				{ label: this.translateService.instant('perf_analytic.daily_billed_energy'), unit: 'kWh' },
				{ label: this.translateService.instant('perf_analytic.peak_power'), unit: 'kW' }
			];

			const consumedDurationFormatted = this.commonUtil.convertSecondsToHoursMinsSecs(+consumedUsageTimeVal);
			const generatedDurationFormatted = this.commonUtil.convertSecondsToHoursMinsSecs(+generatedUsageTimeVal);
			const dataTemp = [+billedVal.toFixed(2), consumedDurationFormatted, generatedDurationFormatted, +billedAvg.toFixed(2), +peakPowerVal.toFixed(2)]

			this.usageDataIsEmpty = sum([+billedVal.toFixed(2), +billedAvg.toFixed(2), +consumedUsageTimeVal.toFixed(2), +generatedUsageTimeVal.toFixed(2), +peakPowerVal.toFixed(2)]) === 0;
			this.usageData = dataTemp.map((value, i) => {
				return { label: this.usageDataChart[i].label, value: value, unit: this.usageDataChart[i].unit };
			});
			this.usageData[1] = [
				this.usageData[1], this.usageData[2]
			];
			delete this.usageData[2];
			this.usageData = Object.values(this.usageData);
		});
	}

	updatePeakPower(dates: any) {
		if (!Array.isArray(dates))
			dates = dates.dateRange;

		this.devicesService.getDailyDetails(this.macAddress, { fromTime: dates[0], toTime: dates[1] }).subscribe((data: any) => {
			this.peakPowerExport = [];
			const days = [];
			const chartData = [];
			this.peakPowerChartData = [];
			for (const dayUnix in data) {
				const dayData = data[dayUnix] || {};
				const day = moment.utc(+dayUnix * 1000).format('l');
				const val = dayData.negative_peak_power || 0;
				days.push(day);
				chartData.push(val.toFixed(2));
				if (dayData.negative_peak_power_data?.time)
					dayData.negative_peak_power_data.time = this.commonUtil.getUnixTimezoneByLocaltime(dayData.negative_peak_power_data.time);
				this.peakPowerChartData.push(dayData.negative_peak_power_data || {});
				if (Object.keys(dayData).length)
					this.peakPowerExport.push([day, val]);
			}

			this.peakPowerChart = {
				labels: days,
				datasets: [{
					type: 'bar',
					backgroundColor: '#42A5F5',
					data: chartData
				}]
			};
		});
	}

	updateEnergyUsage(dates: any) {
		if (!Array.isArray(dates))
			dates = dates.dateRange;

		const min = 0, max = 24;

		this.devicesService.getDailyDetails(this.macAddress, { fromTime: dates[0], toTime: dates[1] }).subscribe((data: any) => {
			this.energyUsageExport = [];
			const times = [],
				chartData = [];

			let energyUsageIsNotEmpty = false;

			for (const day in data) {
				const dayData = data[day] || {};
				if (!dayData.energy_usage)
					continue;

				for (let i = min; i < max; i++) {
					const mappedKey = this.getTimeByIndex(dayData.date, i);
					const value = Math.round(dayData.energy_usage[i] * 100) / 100;

					if (value) energyUsageIsNotEmpty = true;

					const time = this.datePipe.transform(mappedKey, 'MM/dd/yyyy hh:mm:ss a');
					times.push(time);
					chartData.push(value);

					if (dayData.energy_usage[i])
						this.energyUsageExport.push([time, value]);
				}
			}

			this.energyUsageIsNotEmpty = energyUsageIsNotEmpty;

			this.energyUsageChart = {
				labels: times,
				datasets: [{
					borderColor: '#42A5F5',
					fill: false,
					data: chartData
				}]
			};
		});
	}

	updatePerformanceSummary(dates: any) {
		if (!Array.isArray(dates))
			dates = dates.dateRange;

		this.devicesService.getGlitchesSummary(this.macAddress, dates[0], dates[1]).subscribe((data: any) => {
			const
				dips = [],
				swells = [],
				overcurrent = [],
				days = [];

			this.performanceSummaryChartData = [];
			this.performanceSummaryExport = [];
			for (const dayUnix in data) {
				const dayData = data[dayUnix] || {};
				const day = moment.utc(+dayUnix * 1000).format('l');
				days.push(day);

				const dipVal = dayData?.dip?.length ? 1 : 0;
				const swellVal = dayData?.swell?.length ? 1 : 0;
				const overcurrentVal = dayData?.overcurrent?.length ? 1 : 0;

				dips.push(dipVal);
				swells.push(swellVal);
				overcurrent.push(overcurrentVal);

				for (const type of ['dip', 'swell', 'overcurrent']) {
					for (const event of dayData[type] || []) {
						event.time = this.commonUtil.getUnixTimeByZone(this.devices[0].zone_id ? this.devices[0].zone_id : this.siteInfo.zone_id, event.time);
					}
				}
				this.performanceSummaryChartData.push([
					dayData.dip || [],
					dayData.swell || [],
					dayData.overcurrent || [],
				]);

				if (Object.keys(dayData).length)
					this.performanceSummaryExport.push([
						day,
						dipVal,
						swellVal,
						overcurrentVal
					]);
			}

			this.performanceSummaryChart = {
				labels: days,
				datasets: [{
					type: 'bar',
					label: this.translateService.instant('perf_analytic.dips'),
					backgroundColor: '#42A5F5',
					data: dips
				}, {
					type: 'bar',
					label: this.translateService.instant('perf_analytic.swells'),
					backgroundColor: '#66BB6A',
					data: swells
				}, {
					type: 'bar',
					label: this.translateService.instant('perf_analytic.overcurrent'),
					backgroundColor: '#FFCA28',
					data: overcurrent
				}]
			};
		});
	}

	onPeakPowerDataClick(event: { originalEvent: PointerEvent, element: any, dataset: any[] }) {
		const targetEvent = this.peakPowerChartData[event?.element?._index] || {};
		this.eventDialog.data = targetEvent;
		this.eventDialog.visible = true;
	}

	onPerformanceSummaryClick(event: { originalEvent: PointerEvent, element: any, dataset: any[] }) {
		const targetEvent = this.performanceSummaryChartData[event?.element?._index] || {};
		this.glitchDialog.data = targetEvent[event?.element?._datasetIndex];
		this.glitchDialog.visible = true;
	}

	getTimeByIndex(dayTime: number, idx: number) {
		let zoneDiff = new Date().getTimezoneOffset() * -1 * 60 * 1000;
		return new Date((((idx * 60 * 60) + dayTime) * 1000) - zoneDiff);
	}

	exportUsageData() {
		let columnNames = [
			this.translateService.instant('g.date'),
			this.translateService.instant('perf_analytic.billed_kwhrs'),
			this.translateService.instant('perf_analytic.consumed_total_usage_time'),
			this.translateService.instant('perf_analytic.generated_total_usage_time'),
			this.translateService.instant('perf_analytic.peak_power')
		];
		let fileName = this.translateService.instant('perf_analytic.usage_data');

		this.sitePerminssion = this.settingsService.permissionsOfCurrentSite.subscribe((permissions: any) => {
			if (!this.usersService.hasAccessPermission(permissions, 'debug_data')) {
				columnNames = columnNames.filter(column => column != this.translateService.instant('perf_analytic.billed_kwhrs'));
				this.usageDataExport.map((row: any) => row.splice(1, 1)); // Index 1 is the Consumed Energy (kWh) index.
			}
		});

		this.commonUtil.exportToCsv(columnNames, this.usageDataExport, fileName);
	}

	exportPeakPower() {
		let columnNames = [
			this.translateService.instant('g.date'),
			this.translateService.instant('g.value')
		];
		let fileName = this.translateService.instant('perf_analytic.peak_power');
		this.commonUtil.exportToCsv(columnNames, this.peakPowerExport, fileName);
	}

	exportEnergyUsage() {
		let columnNames = [
			this.translateService.instant('g.time'),
			this.translateService.instant('g.value')
		];
		let fileName = this.translateService.instant('perf_analytic.energy_usage');
		this.commonUtil.exportToCsv(columnNames, this.energyUsageExport, fileName);
	}

	exportPerformanceSummary() {
		let columnNames = [
			this.translateService.instant('g.time'),
			this.translateService.instant('perf_analytic.dips'),
			this.translateService.instant('perf_analytic.swells'),
			this.translateService.instant('perf_analytic.overcurrent')
		];
		let fileName = this.translateService.instant('perf_analytic.performance_summary');
		this.commonUtil.exportToCsv(columnNames, this.performanceSummaryExport, fileName);
	}

	exportQuarterlyUsageData() {
		let columnNames = [
			this.translateService.instant('g.quarter'),
			this.translateService.instant('perf_analytic.billed_kwhrs'),
			this.translateService.instant('perf_analytic.daily_billed_kwhrs'),
			this.translateService.instant('perf_analytic.total_usage_time'),
			this.translateService.instant('perf_analytic.total_generated_time'),
			this.translateService.instant('perf_analytic.peak_power')
		];
		let fileName = this.translateService.instant('perf_analytic.quarters_data');
		this.commonUtil.exportToCsv(columnNames, this.quarterlyUsageDataExport, fileName);
	}

	exportMonthlyUsageData() {
		let columnNames = [
			this.translateService.instant('g.year_month'),
			this.translateService.instant('perf_analytic.billed_kwhrs'),
			this.translateService.instant('perf_analytic.daily_billed_kwhrs'),
			this.translateService.instant('perf_analytic.total_usage_time'),
			this.translateService.instant('perf_analytic.total_generated_time'),
			this.translateService.instant('perf_analytic.peak_power')
		];
		let fileName = this.translateService.instant('perf_analytic.monthly_data');
		this.commonUtil.exportToCsv(columnNames, this.monthlyUsageDataExport, fileName);
	}

	ngOnDestroy() {
		this.sitePerminssion.unsubscribe();
	}
}